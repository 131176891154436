import React, {useContext, useEffect, useState} from 'react';
import LiveCard from "./LiveCard";
import {Link, Route, Routes} from "react-router-dom";
import axios from "axios";
import {UserContext} from "../../../App";

const Live = () => {
    const [data, setData] = useState([]);
    const [user] = useContext(UserContext);
    useEffect(()=>{
        axios.get(process.env.REACT_APP_API_BASE_URL+'/live/moderation',{
            headers: {
                'Authorization': 'Bearer '+user.token
            }
        }).then(r=> {
            setData(r.data.lives);
        });
        },[]);
    return (
        <div className="container">
            <h1 className="color-a">Lives</h1>
            <hr/>
            <Link to={'/dashboard/live/create'}>
                <button className="btn btn-b">Create +</button>
            </Link>
            <section className="row mt-5 mb-5">
                {
                    data.map(live=>(
                        <LiveCard
                            key={live.key}
                            data={live}
                        />
                    ))
                }
            </section>
        </div>
    );
};

export default Live;