import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical, faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../Components/Utilities/LoadingButton";
import axios from "axios";
import {UserContext} from "../../../App";
import {toast} from "react-toastify";
import moment from "moment";

const EditExpenseModal = ({show, setShow, editValue, setEditValue, loadRecords}) => {
    const [user] = useContext(UserContext);
    const handleChange = (e) => {
        setEditValue({
            ...editValue,
            [e.target.id]: e.target.value
        });
    }
    const handleExpensesChange = (e, index) => {
        const expenses = [...editValue.expenses];
        expenses[index][e.target.id] = e.target.value;
        setEditValue({
            ...editValue,
            total: expenses.reduce((a, b) => a + (Number.parseInt(b['price']) || 0), 0),
            expenses: expenses
        });
    }
    const handleUpdate = (setLoading) => {
        if(editValue.date !== '' && editValue.total > 0 && editValue.expenses.length > 0){
            let expensesError = {error: false, message: ''}
            editValue.expenses.forEach((expense, index) => {
                if(expense.item === '' || expense.price === ''){
                    expensesError.error = true;
                    expensesError.message = 'Please fill all the fields';
                }
            });
            if(!expensesError.error){
                setLoading(true);
                axios.put(process.env.REACT_APP_API_BASE_URL + '/prp/expense/records/update', editValue, {
                    headers: {
                        Authorization: 'Bearer ' + user.token,
                    },
                }).then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        toast(response.data.error, {type: 'error'});
                    } else {
                        toast('Record updated successfully', {type: 'success'});
                        setShow(false);
                        loadRecords();
                    }
                }).catch(error => {
                    setLoading(false);
                    toast('Something went wrong', {type: 'error'});
                });
            }else{
                toast.error(expensesError.message);
            }
        }else{
            toast.error('Please fill all the fields');
        }
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal ">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> Edit Record</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="date" className="form-label">Date</label>
                        <input type="date" className="form-control" id="date" onChange={handleChange} defaultValue={moment(editValue.date).format('YYYY-MM-DD')}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="total" className="form-label">Total</label>
                        <input type="number" className="form-control" id="total" onChange={handleChange} value={editValue.total}/>
                    </div>
                    <div className="mb-3 mt-3">
                        <label htmlFor="expenses" className="form-label">Expenses</label>
                        {
                            show && editValue.expenses.map((expense, index) => {
                                return (
                                    <div key={index} className="row mt-2">
                                        <div className="col-7">
                                            <input type="text" className="form-control" placeholder="Item" id="item" onChange={(e) => handleExpensesChange(e, index)} defaultValue={expense.item}/>
                                        </div>
                                        <div className="col-3">
                                            <input type="number" className="form-control" placeholder="Price" id="price" onChange={(e) => handleExpensesChange(e, index)} defaultValue={expense.price}/>
                                        </div>
                                        <div className="col-2 d-flex justify-content-end">
                                            <label htmlFor="delete" className="form-label"></label>
                                            <button className={`btn btn-b ${index === 0 && 'disabled'}`} onClick={() => {
                                                const expenses = [...editValue.expenses];
                                                if(expenses.length > 1){
                                                    expenses.splice(index, 1);
                                                    setEditValue({
                                                        ...editValue,
                                                        total: expenses.reduce((a, b) => a + (Number.parseInt(b['price']) || 0), 0),
                                                        expenses: expenses
                                                    });
                                                }
                                            }}>
                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className="col-12">
                            <button className="btn btn-b float-end" onClick={() => {
                                const expenses = [...editValue.expenses];
                                expenses.push({item: '', price: ''});
                                setEditValue({
                                    ...editValue,
                                    expenses: expenses
                                });
                            }}>
                                <FontAwesomeIcon icon={faPlusCircle}/>
                            </button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange} defaultValue={editValue.comment}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=> {
                        setShow(false);
                        setEditValue({});
                    }}>Close</button>
                    <LoadingButton text="Update" onClick={handleUpdate}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default EditExpenseModal;