import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {random} from "../../../Helper/helper";
import {UserContext} from "../../../App";
import DummyImage from "../../../Assets/Images/dummy_1.png";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faClose, faCross} from "@fortawesome/free-solid-svg-icons";

const UpdateLive = () => {
    const navigation = useNavigate();
    const [user] = useContext(UserContext);
    const {slug} = useParams();
    const [data, setData] = useState([]);
    const displayRef = useRef(null);
    const imageRef = useRef(null);
    const keyRef = useRef(null);
    const linkRef = useRef(null);
    const titleRef = useRef(null);
    const slugRef = useRef(null);
    const descriptionRef = useRef(null);
    let enabled = 1;
    let login_required = 0;
    let deleteImage = 0;
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/live/moderation/${slug}`,{
            headers: {
                "Authorization" : `Bearer ${user.token}`
            }
        }).then(r=>{
            setData(r.data.live);
        });
    },[]);
    const removeimage = () => {
        displayRef.current.src = DummyImage;
        deleteImage = 1;
    }
    const generateSlug = () => {
        const title = titleRef.current.value;
        slugRef.current.value = title.toLowerCase().replace(/ /g, '-');
    }
    const handleEnable = event => {
        enabled = Number.parseInt(event.target.value);
    }
    const handleLoginRequired = event => {
        login_required = Number.parseInt(event.target.value);
    }
    const handleSelect = event => {
        displayRef.current.src = URL.createObjectURL(event.target.files[0]);
    }
    const handleKey = () => {
        keyRef.current.value = random(32);
    }
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('image', imageRef.current.files[0]);
        formData.append('key', keyRef.current.value);
        formData.append('link', linkRef.current.value);
        formData.append('title', titleRef.current.value);
        formData.append('slug', slugRef.current.value);
        formData.append('description', descriptionRef.current.value);
        formData.append('is_active', enabled);
        formData.append('login_required', login_required);
        formData.append('id', data.id);
        formData.append('deleteImage', deleteImage);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/live/moderation/update`, formData, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then(r=>{
            console.log(r.data);
            if (r.data.error){
                toast(r.data.error, {type: 'error'});
            } else {
                toast('Live updated successfully', {type: 'success'});
                navigation('/dashboard/live');
            }
        });
    }
    const handleDelete = event => {
        const confirm = window.confirm('Are you sure to delete this live?');
        if (!confirm) return;
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/live/moderation/delete`,{
            headers: {
                "Authorization" : `Bearer ${user.token}`
            },
            data: {id: data.id}
        }).then(r=>{
            if(r.data.error){
                toast(r.data.error, {type: 'error'});
            } else {
                toast('Live deleted successfully', {type: 'success'});
                navigation('/dashboard/live');
            }
        });
    }
    return (
        <div className="container">
            <div className="card bg-a">
                <div className="card-header pt-3">
                    <h2 className="color-a">{data.title}</h2>
                </div>
                <div className="card-body">
                    <div className="row d-flex align-items-end">
                        <div className="col-md-2 mb-3">
                            <img ref={displayRef} className="img img-thumbnail" src={data.image ? process.env.REACT_APP_API_BASE_URL+'/'+data.image.replace('public','storage') : DummyImage} alt={data.title}/>
                            <button onClick={removeimage} className="btn btn-a btn-sm user-image-profile-upload-btn" >
                                <FontAwesomeIcon icon={faClose} className="text-white" />
                            </button>
                        </div>
                        <div className="col-md-4 mb-3">
                            <input ref={imageRef} onChange={handleSelect} type="file" className="form-control"/>
                        </div>
                        <div className="col-md-3 mb-3">
                            <select className="form-control" onChange={handleLoginRequired} value={data.login_required}>
                                <option className="form-control" value={0} >Login Not Required</option>
                                <option className="form-control" value={1} >Login Required</option>
                            </select>
                        </div>
                        <div className="col-md-3 mb-3">
                            <select className="form-control" onChange={handleEnable} value={data.is_active}>
                                <option className="form-control" value={1} >Enable</option>
                                <option className="form-control" value={0} >Disable</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mb-3">
                            <input ref={keyRef} type="text" className="form-control" placeholder="Key" defaultValue={data.key}/>
                        </div>
                        <div className="col-md-2 mb-3">
                            <button className="btn btn-b float-end" onClick={handleKey}>Generate</button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <input ref={linkRef} type="url" className="form-control" placeholder="Link" defaultValue={data.link}/>
                    </div>
                    <div className="mb-3">
                        <input ref={titleRef} type="text" className="form-control" placeholder="Title" defaultValue={data.title} onChange={generateSlug}/>
                    </div>
                    <div className="mb-3">
                        <input ref={slugRef} type="text" className="form-control" placeholder="Slug" defaultValue={data.slug}/>
                    </div>
                    <div className="mb-3">
                        <textarea ref={descriptionRef} rows="10" className="form-control" placeholder="Description" defaultValue={data.description}></textarea>
                    </div>
                    <div className="d-flex justify-content-between">
                        <button onClick={handleDelete} className="btn btn-b">Delete</button>
                        <button onClick={handleSubmit} className="btn btn-b">Update</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateLive;