import React, {useState} from 'react';

const LoadingButton = ({text, type, className, onClick, data}) => {
    const [loading, setLoading] = useState(false);
    return (
        <button type={type || 'button'} onClick={event => onClick(setLoading,event,data)} className={'btn btn-b float-end align-items-center justify-content-center '+className}>
            <div className="d-flex ">
                <span>{text}</span>
                {loading && <div className="loader"></div>}
            </div>
        </button>
    );
};

export default LoadingButton;