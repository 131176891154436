import {Link} from "react-router-dom";
import DummyImage from "../../../Assets/Images/dummy_1.png";

const LiveCard = (props) => {
    const {slug, updated_at, image, title, description, login_required, is_active} = props.data;
    return (
        <div className="col-md-4 mb-4 article-card">
            <div className="card bg-a">
                {image ? <img src={process.env.REACT_APP_API_BASE_URL+'/'+image.replace('public','storage')} className="card-img-top" alt={title}/> : <img src={DummyImage} className="card-img-top" alt={title}/> }
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p>
                        {Number.parseInt(is_active) ? <span className="badge bg-success">Enable</span> : <span className="badge bg-secondary">Disable</span>}
                        &nbsp;&nbsp;
                        {Number.parseInt(login_required) ? <span className="badge bg-danger">Login Required</span> : <span className="badge bg-success">Login Not Required</span>}
                    </p>
                    <p className="card-text fs-6">{description}</p>
                </div>
                <div className="card-footer d-flex align-items-baseline justify-content-between">
                    <Link to={`/dashboard/live/${slug}`}>
                        <button className="btn btn-a">Edit</button>
                    </Link>
                    <p>{
                        new Date(updated_at).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        })
                    }</p>
                </div>
            </div>
        </div>
    );
};

export default LiveCard;