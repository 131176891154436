import React, {useContext, useEffect, useState} from 'react';
import LiveCard from "./LiveCard";
import axios from "axios";
import {isUserAdmin, isUserLoggedIn} from "../../../Helper/auth";
import {UserContext} from "../../../App";

const Live = () => {
    const [data, setData] = useState([]);
    const [user] = useContext(UserContext);
    useEffect(()=>{
        let url = process.env.REACT_APP_API_BASE_URL+'/live';
        let config = {};
        if(isUserLoggedIn()){
            if(isUserAdmin()){
                url += '/moderation'
            }else{
                url += '/protected';
            }
            config = {
                headers: {
                    'Authorization': 'Bearer '+user.token
                }
            }
        }
        axios.get(url,config).then(r=> {
                setData(r.data.lives);
            });
    },[]);
    return (
        <div className="container">
            <h1 className="color-a">Lives</h1>
            <hr/>
            <section className="row mt-5 mb-5">
                {
                    data.length > 0 ? data.map(d=>(
                        <LiveCard
                            key={d.key}
                            data={d}
                        />
                    )) : <h3 className="text-center">No live available right now</h3>
                }
            </section>
        </div>
    );
};

export default Live;