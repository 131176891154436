import React, {useContext, useRef} from 'react';
import LoadingButton from "../../../Utilities/LoadingButton";
import {UserContext} from "../../../../App";
import axios from "axios";
import {toast} from "react-toastify";

const PasswordSection = () => {
    const [user, setUser] = useContext(UserContext);
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const changePassword = (setLoading) => {
        const password = passwordRef.current.value;
        const confirmPassword = confirmPasswordRef.current.value;
        if(password !== '' && confirmPassword !== ''){
            if(password === confirmPassword){
                setLoading(true);
                axios.put(process.env.REACT_APP_API_BASE_URL + '/profile/update-password', {id:user.id,password},{
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    }
                }).then(response => {
                    setLoading(false);
                    if(response.data.error){
                        toast(response.data.error, {type: 'error'});
                    }else{
                        passwordRef.current.value = '';
                        confirmPasswordRef.current.value = '';
                        toast('Password changed successfully', {type: 'success'});
                    }
                }).catch(err => {
                    setLoading(false);
                    toast('Something went wrong', {type: 'error'});
                });
            }else{
                toast('Password and Confirm Password must be same', {type: 'warning'})
            }
        }else{
            toast('Password and Confirm Password must be filled', {type: 'warning'})
        }
    }
    return (
                <div className="card bg-a mb-3">
                    <div className="card-header">
                        <div className="text-center mt-3">
                            <h4 className="card-title">Change Password</h4>
                        </div>
                    </div>
                    <div className="card-body mb-3">
                        <div className="d-flex justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                                <div className="password-change-area">
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input ref={passwordRef} type="password" className="form-control" id="password" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                        <input ref={confirmPasswordRef} type="password" className="form-control" id="confirmPassword" />
                                    </div>
                                    <LoadingButton text="Change Password" onClick={changePassword} />
                                    <br/>
                                    <div className="mt-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    );
};

export default PasswordSection;