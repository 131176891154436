import React, {useEffect, useRef} from 'react';
import {NavLink, Route, Routes} from "react-router-dom";
import Profile from "./Profile/Profile";

const Dashboard = () => {
    const dashboardContainer = useRef(null);
    useEffect(()=>{
        const header = document.getElementById('header');
        const footer = document.getElementById('footer');
        const extraHeight = header.offsetHeight + footer.offsetHeight;
        if(dashboardContainer.current){
            dashboardContainer.current.style.minHeight = `${window.innerHeight - extraHeight - 10}px`;
        }
    });
    return (
        <div className="container-fluid">
            <div className="dashboard-wrapper">
                <div className="dashboard-sidebar bg-glass">
                    <ul className="list-group">
                        <NavLink to="/dashboard" className={(navInfo)=> {
                            let pathParams = window.location.pathname.split('/');
                            let match = pathParams[pathParams.length-1] === 'dashboard';
                            return match ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'
                        }} >
                            <li className="list-group-item nav-glass">Dashboard</li>
                        </NavLink>
                        <NavLink to="/dashboard/profile" className={navInfo=>navInfo.isActive ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'} >
                            <li className="list-group-item nav-glass">Profile</li>
                        </NavLink>
                    </ul>
                </div>
                <div ref={dashboardContainer} className="dashboard-content">
                    <Routes>
                        <Route path='profile' element={<Profile/>} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;