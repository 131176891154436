import React, {useRef} from 'react';
import LoadingButton from "../../Utilities/LoadingButton";
import LoadingLink from "../../Utilities/LoadingLink";
import axios from "axios";

const ForgotPassword = () => {
    const alertRef = useRef();
    const resendRef = useRef();
    const verifyRef = useRef();
    const resendOtpRef = useRef();
    const haveOtpRef = useRef();
    const emailRef = useRef();
    const otpRef = useRef();
    const passwordRef = useRef();
    const resendOtpClick = (setLoading,event) => {
        emailRef.current.value = localStorage.getItem('unverifiedEmail') || '';
        resendRef.current.classList.remove('d-none');
        verifyRef.current.classList.add('d-none');
        resendOtpRef.current.classList.add('d-none');
        haveOtpRef.current.classList.remove('d-none');
    }
    const haveOtpClick = (setLoading,event) => {
        otpRef.current.value = '';
        resendRef.current.classList.add('d-none');
        verifyRef.current.classList.remove('d-none');
        resendOtpRef.current.classList.remove('d-none');
        haveOtpRef.current.classList.add('d-none');
    }

    const sendOtp = (setLoading,event) => {
        const email = emailRef.current.value;
        if(email !== ''){
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL+'/auth/forgot-password', {email}).then(res => {
                setLoading(false);
                if(res.data.error){
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerHTML = res.data.error;
                    setTimeout(() => {
                        alertRef.current.classList.add('d-none');
                    }, 5000);
                }else{
                    alertRef.current.classList.remove('alert-danger');
                    alertRef.current.classList.add('alert-success');
                    alertRef.current.innerHTML = 'OTP sent to your email, please check';
                    alertRef.current.classList.remove('d-none')
                    setTimeout(() => {
                        alertRef.current.classList.remove('alert-success');
                        alertRef.current.classList.add('alert-danger');
                        alertRef.current.classList.add('d-none');
                    }, 3000);
                    resendRef.current.classList.add('d-none');
                    verifyRef.current.classList.remove('d-none');
                    resendOtpRef.current.classList.remove('d-none');
                    haveOtpRef.current.classList.add('d-none');
                }
            }).catch(err => {
                setLoading(false);
            });
        }
    }

    const setPassword = (setLoading,event) => {
        const otp = otpRef.current.value;
        const password = passwordRef.current.value;
        if(otp !== '' && password !== ''){
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL+'/auth/reset-password', {otp,new_password:password}).then(res => {
                setLoading(false);
                if(res.data.error){
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerHTML = res.data.error;
                    setTimeout(() => {
                        alertRef.current.classList.add('d-none');
                    }, 5000);
                }else{
                    alertRef.current.classList.remove('alert-danger');
                    alertRef.current.classList.add('alert-success');
                    alertRef.current.innerHTML = 'Password reset successfully';
                    alertRef.current.classList.remove('d-none')
                    setTimeout(() => {
                        alertRef.current.classList.remove('alert-success');
                        alertRef.current.classList.add('alert-danger');
                        alertRef.current.classList.add('d-none');
                        window.location.replace('/auth/login');
                    }, 3000);
                }
            }).catch(err => {
                setLoading(false);
            });
        }
    }

    return (
        <div className="mt-5 d-flex align-items-center justify-content-center">
            <div className="col-md-4 mb-5">
                <div className="card bg-a">
                    <div className="card-header text-center pt-3">
                        <h2 className="color-a">Set New Password</h2>
                    </div>
                    <div className="card-body">
                        <div ref={alertRef} className="alert alert-danger d-none" role="alert">
                        </div>
                        <div ref={resendRef}>
                            <div className="mb-3">
                                <input ref={emailRef} type="email" className="form-control" placeholder="Email"/>
                            </div>
                            <LoadingButton text="Send OTP" type="button" onClick={sendOtp} />
                        </div>
                        <div ref={verifyRef} className="d-none">
                            <div className="mb-3">
                                <input ref={otpRef} type="text" className="form-control" placeholder="Enter OTP"/>
                            </div>
                            <div className="mb-3">
                                <input ref={passwordRef} type="password" className="form-control" placeholder="Enter New Password"/>
                            </div>
                            <LoadingButton text="Submit" type="button" onClick={setPassword} />
                        </div>
                        <br/><br/>
                        <p ref={resendOtpRef} className="d-none">Don't get OTP? <LoadingLink text="Resend" handleClick={resendOtpClick} /></p>
                        <p ref={haveOtpRef}><LoadingLink text="I have OTP" handleClick={haveOtpClick} /></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;