import React, {useEffect, useRef, useState} from 'react';
import Meter from "./Meter";
import {Route, Routes} from "react-router-dom";
import Meters from "./Meters";
import RecordsTimeline from "./RecordsTimeline";

const Electricity = () => {
    return (
        <div className="container">
            <Routes>
                <Route path="/" element={<Meters/>}/>
                <Route path="timeline" element={<RecordsTimeline/>}/>
                <Route path=":meterId" element={<Meter/>}/>
            </Routes>
        </div>
    );
};

export default Electricity;