import React, {useContext, useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical, faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../Components/Utilities/LoadingButton";
import axios from "axios";
import {UserContext} from "../../../App";
import {toast} from "react-toastify";

const EditExpenseModal = ({show, setShow, editValue, setEditValue, loadExpenses}) => {
    const [user] = useContext(UserContext);
    const handleUpdate = (setLoading) => {
        setLoading(true);
        if(editValue.title !== '' && editValue.budget > 0 && editValue.deposits.length > 0){
            let depositError = {error: false, message: ''}
            editValue.deposits.forEach((deposit, index) => {
                if(deposit.amount === '' || deposit.date === ''){
                    depositError.error = true;
                    depositError.message = 'Please fill all the fields';
                }
            });
            if(!depositError.error){
                setLoading(true);
                axios.put(process.env.REACT_APP_API_BASE_URL + '/prp/expense/update', editValue, {
                    headers: {
                        Authorization: 'Bearer ' + user.token,
                    },
                }).then(response => {
                    setLoading(false);
                    if (response.data.error) {
                        toast(response.data.error, {type: 'error'});
                    } else {
                        toast('Expense updated successfully', {type: 'success'});
                        setShow(false);
                        loadExpenses();
                    }
                }).catch(error => {
                    setLoading(false);
                    toast('Something went wrong', {type: 'error'});
                });
            }else{
                setLoading(false);
                toast.error(depositError.message);
            }
        }else{
            toast.error('Please fill all the fields');
        }
    }
    const handleChange = (e) => {
        setEditValue({
            ...editValue,
            [e.target.id]: e.target.value
        });
    }
    const handleDepositsChange = (e, index) => {
        const deposits = [...editValue.deposits];
        deposits[index][e.target.id] = e.target.value;
        setEditValue({
            ...editValue,
            budget: deposits.reduce((a, b) => a + (Number.parseInt(b['amount']) || 0), 0),
            deposits: deposits
        });
    }
    return (
        <Modal className="modal-lg" centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal ">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> Edit Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input type="text" className="form-control" id="title" onChange={handleChange} value={editValue.title}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="budget" className="form-label">Budget</label>
                        <input type="number" className="form-control" id="budget" onChange={handleChange} value={editValue.budget}/>
                    </div>
                    <div className="mb-3 mt-3">
                        <label htmlFor="deposits" className="form-label">Deposits</label>
                        {
                            show && editValue.deposits.map((deposit, index) => {
                                return (
                                    <div key={index} className="row mt-2">
                                        <div className="col-2">
                                            <input type="number" className="form-control" placeholder="Amount" id="amount" onChange={(e) => handleDepositsChange(e, index)} defaultValue={deposit.amount}/>
                                        </div>
                                        <div className="col-6">
                                            <input type="text" className="form-control" placeholder="Description" id="description" onChange={(e) => handleDepositsChange(e, index)} defaultValue={deposit.description}/>
                                        </div>
                                        <div className="col-3">
                                            <input type="date" className="form-control" placeholder="date" id="date" onChange={(e) => handleDepositsChange(e, index)} defaultValue={deposit.date}/>
                                        </div>
                                        <div className="col-1">
                                            <label htmlFor="delete" className="form-label"></label>
                                            <button className={`btn btn-b ${index === 0 && 'disabled'}`} onClick={() => {
                                                const deposits = [...editValue.deposits];
                                                if(deposits.length > 1){
                                                    deposits.splice(index, 1);
                                                    setEditValue({
                                                        ...editValue,
                                                        budget: deposits.reduce((a, b) => a + (Number.parseInt(b['amount']) || 0), 0),
                                                        deposits: deposits
                                                    });
                                                }
                                            }}>
                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className="col-12">
                            <button className="btn btn-b float-end" onClick={() => {
                                const deposits = [...editValue.deposits];
                                deposits.push({amount: '', description: '', date: ''});
                                setEditValue({
                                    ...editValue,
                                    deposits: deposits
                                });
                            }}>
                                <FontAwesomeIcon icon={faPlusCircle}/> Add More
                            </button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange} defaultValue={editValue.comment}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Update" onClick={handleUpdate}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default EditExpenseModal;