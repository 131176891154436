import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../../Components/Utilities/LoadingButton";
import axios from "axios";
import {toast} from "react-toastify";
import {UserContext} from "../../../../App";

const AddRecordModal = ({show, setShow, loadRecords}) => {
    const [user] = useContext(UserContext);
    const initialRecord = {
        date: '',
        bill_number: '',
        billing_month: 'January',
        billing_year: '2021',
        amount: 0,
        payment_method: 'bKash',
        transaction_id: '',
        comment: ''
    };
    const [newRecord, setNewRecord] = useState(initialRecord);
    useEffect(() => {
        setNewRecord(initialRecord);
    }, []);
    const handleAdd = (setLoading) => {
        if(newRecord.date !== '' && newRecord.bill_number !== '' && newRecord.billing_month !== '' && newRecord.billing_year !== '' && newRecord.amount > 0 && newRecord.payment_method !== '' && newRecord.transaction_id !== ''){
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL + '/prp/utility-bills/water/add-record', newRecord, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).then(response => {
                setLoading(false);
                if(response.data.error){
                    toast(response.data.error, {type: 'error'});
                }else{
                    loadRecords();
                    toast('Record added successfully', {type: 'success'});
                    setShow(false);
                    setNewRecord(initialRecord);
                }
            }).catch(error => {
                toast('Something went wrong', {type: 'error'});
                setLoading(false);
            });
        }else {
            toast('Please fill up all the fields', {type: 'error'});
        }
    }

    const handleChange = (e) => {
        setNewRecord({...newRecord, [e.target.id]: e.target.value});
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> New Record</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="date" className="form-label">Date</label>
                        <input type="datetime-local" className="form-control bg-b text-white" id="date" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="bill_number" className="form-label">Bill Number</label>
                        <input type="text" className="form-control bg-b text-white" id="bill_number" onChange={handleChange}/>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="billing_month" className="form-label">Billing Month</label>
                                <select className="form-select bg-b text-white" id="billing_month" onChange={handleChange}>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="billing_year" className="form-label">Billing Year</label>
                                <select className="form-select bg-b text-white" id="billing_year" onChange={handleChange}>
                                    {Array.from({length: 20}, (v, k) => k + 2021).map((year, index) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label">Amount</label>
                                <input type="number" className="form-control bg-b text-white" id="amount" onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="payment_method" className="form-label">Payment Method</label>
                                <select className="form-select bg-b text-white" id="payment_method" onChange={handleChange}>
                                    <option value="bKash">bKash</option>
                                    <option value="Nagad">Nagad</option>
                                    <option value="Rocket">Rocket</option>
                                    <option value="Bank">Bank</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="transaction_id" className="form-label">Transaction ID</label>
                        <input type="text" className="form-control bg-b text-white" id="transaction_id" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control bg-b text-white" id="comment" rows="3" onChange={handleChange}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Add" onClick={handleAdd}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default AddRecordModal;