import avatar from '../Assets/Images/user-avatar.png';
export const getUserData = () => {
    const loginData = localStorage.getItem('loginData')
    if (loginData) {
        let data = JSON.parse(loginData)
        let user = data.user;
        user.token = data.token;
        return user;
    } else {
        return {}
    }
}

export const getUserMetadata = key => {
    const userData = getUserData()
    if (userData && userData.meta) {
        return userData.meta[key] || null
    } else {
        return null
    }
}

export const getUserAvatar = () => {
    const imageUrl = getUserMetadata('image_url');
    if (imageUrl) {
        return process.env.REACT_APP_API_BASE_URL + imageUrl.replace('public', '/storage');
    }else {
        return avatar;
    }
}
export const isUserLoggedIn = () =>{
    let loggedIn = false;
    let userData = getUserData();
    if(userData && userData.token){
        let tokenData = JSON.parse(atob(userData.token));
        let now = Math.floor(Date.now() / 1000);
        if(tokenData.expires > now){
            loggedIn = true;
        }
    }
    return loggedIn;
}

export const isUserAdmin = () => {
    let isAdmin = false;
    const userData = getUserData()
    if (userData && userData.administrator === 1) {
        isAdmin = true;
    }
    return isAdmin;
}