import React, {useContext, useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faClose, faFileMedical} from "@fortawesome/free-solid-svg-icons";
import UserAvatar from "../../../Assets/Images/user-avatar.png";
import LoadingButton from "../../../Components/Utilities/LoadingButton";
import {dataUrlToFile} from "../../../Helper/helper";
import axios from "axios";
import {UserContext} from "../../../App";
import {toast} from "react-toastify";

const EditWifeModal = ({show, setShow, editValue, setEditValue, loadWives}) => {
    const [user, setUser] = useContext(UserContext);
    const [image, setImage] = useState(null);
    const imageRef = useRef();
    const editFileRef = useRef();
    const handleUpdate = (setLoading) => {
        setLoading(true);
        if(editValue.name !== '' && editValue.dob !== '' && editValue.marriage_date !== '' && editValue.is_alive !== '' && editValue.is_divorced !== ''){
            const formData = new FormData();
            formData.append('id', editValue.id);
            formData.append('image', image);
            formData.append('name', editValue.name);
            formData.append('dob', editValue.dob);
            formData.append('marriage_date', editValue.marriage_date);
            formData.append('is_alive', editValue.is_alive);
            formData.append('death_date', editValue.death_date);
            formData.append('is_divorced', editValue.is_divorced);
            formData.append('divorce_date', editValue.divorce_date);
            formData.append('comment', editValue.comment);
            formData.append('_method', 'PUT');
            axios.post(process.env.REACT_APP_API_BASE_URL + '/prp/wife/update-wife', formData, {
                headers: {
                    Authorization: 'Bearer ' + user.token,
                }
            }).then(res => {
                if(!res.data.error){
                    toast.success('Wife updated successfully');
                    setShow(false);
                    loadWives();
                }else {
                    toast.error(res.data.error);
                }
                setLoading(false);
            }).catch(err => {
                toast.error(err.message);
                setLoading(false);
            });
        }else{
            toast.error('Please fill all the fields');
            setLoading(false);
        }
    }
    const handleChange = (e) => {
        setEditValue({
            ...editValue,
            [e.target.id]: e.target.value
        });
    }
    useEffect(() => {
        if(show){
            setImage(editValue.image ? dataUrlToFile(editValue.image) : null);
            imageRef.current.src = editValue.image ? editValue.image : UserAvatar;
        }
    },[show]);
    useEffect(() => {
        if(image){
            const reader = new FileReader();
            reader.onload = () => {
                imageRef.current.src = reader.result;
            }
            reader.readAsDataURL(image);
        }
        setEditValue({...editValue,
            image: image
        })
    },[image]);
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> New Wife</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="d-flex mb-3 align-items-center justify-content-center pt-3">
                        <img ref={imageRef} alt="profile" className="img-thumbnail user-image-profile" />
                        <button
                            className="btn btn-a btn-sm user-image-profile-upload-btn"
                            onClick={()=> {
                                if(image){
                                    setImage(null);
                                    imageRef.current.src = UserAvatar;
                                }else{
                                    editFileRef.current.click()
                                }
                            }}
                        >
                            {
                                image ? <FontAwesomeIcon icon={faClose} className="text-white" /> : <FontAwesomeIcon icon={faCamera} className="text-white" />
                            }
                        </button>
                        <input type="file" ref={editFileRef} className="d-none" onChange={e=> {
                            setImage(e.target.files[0])
                        }}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" className="form-control" id="name" onChange={handleChange} value={editValue.name}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="dob" className="form-label">Date of Birth</label>
                        <input type="date" className="form-control" id="dob" onChange={handleChange} value={editValue.dob}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="marriage_date" className="form-label">Marriage Date</label>
                        <input type="date" className="form-control" id="marriage_date" onChange={handleChange} value={editValue.marriage_date}/>
                    </div>
                    <div className="row">
                        <div className={editValue.is_alive == '1' ? 'col-md-12' : 'col-md-6'}>
                            <div className="mb-3">
                                <label htmlFor="is_alive" className="form-label">Alive</label>
                                <select className="form-select" id="is_alive" onChange={handleChange} defaultValue={editValue.is_alive}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        {
                            editValue.is_alive == '0' &&
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="death_date" className="form-label">Death Date</label>
                                    <input type="date" className="form-control" id="death_date" onChange={handleChange} value={editValue.death_date}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className={editValue.is_divorced == '0' ? 'col-md-12' : 'col-md-6'}>
                            <div className="mb-3">
                                <label htmlFor="is_divorced" className="form-label">Divorced</label>
                                <select className="form-select" id="is_divorced" onChange={handleChange} defaultValue={editValue.is_divorced}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>
                        {
                            editValue.is_divorced == '1' &&
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="divorce_date" className="form-label">Divorce Date</label>
                                    <input type="date" className="form-control" id="divorce_date" onChange={handleChange} value={editValue.divorce_date}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange} defaultValue={editValue.comment} />
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Update" onClick={handleUpdate}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default EditWifeModal;