import React, {useContext, useEffect, useState} from 'react';
import Loader from "../../../Components/Utilities/Loader";
import moment from "moment/moment";
import UserAvatar from "../../../Assets/Images/user-avatar.png";
import {timestampToToday} from "../../../Helper/helper";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd, faChartColumn,
    faChevronLeft,
    faChevronRight,
    faFolderOpen,
    faPlusCircle,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../../../App";
import axios from "axios";
import {toast} from "react-toastify";
import AddExpenseModal from "./AddExpenseModal";
import EditExpenseModal from "./EditExpenseModal";
import DeleteExpenseModal from "./DeleteExpenseModal";

const Expenses = () => {
    const [user] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [expenses, setExpenses] = useState([]);
    const [totalExpense, setTotalExpense] = useState(0);
    const [addExpenseShow, setAddExpenseShow] = useState(false);
    const [editExpenseShow, setEditExpenseShow] = useState(false);
    const [editExpenseValues, setEditExpenseValues] = useState({});
    const [deleteExpenseShow, setDeleteExpenseShow] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const limit = 100;
    const getExpenses = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/expense/get/${limit}/${page}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }).then(res => {
            if (!res.data.error) {
                setExpenses(res.data.data);
                setTotalExpense(res.data.total);
                setLoading(false);
            }else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            toast('Something went wrong!\nData could not be loaded from the server.', {type: 'error'});
        });
    }
    const handleSearch = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/prp/expense/search/${limit}/${page}`, {
            search: search
        }, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(res => {
            setExpenses(res.data.data);
            setTotalExpense(res.data.total);
        }).catch(error => {
            console.log(error);
        });
    }
    useEffect(() => {
        getExpenses();
    }, [page]);
    useEffect(() => {
        setTotalPage(Math.ceil(totalExpense / limit));
    }, [totalExpense])
    return (
        <div className="container">
            <AddExpenseModal
                show={addExpenseShow}
                setShow={setAddExpenseShow}
                loadExpenses={getExpenses}
            />
            <EditExpenseModal
                show={editExpenseShow}
                setShow={setEditExpenseShow}
                editValue={editExpenseValues}
                setEditValue={setEditExpenseValues}
                loadExpenses={getExpenses}
            />
            <DeleteExpenseModal
                show={deleteExpenseShow}
                setShow={setDeleteExpenseShow}
                deleteId={deleteId}
                loadExpenses={getExpenses}
            />
            <div className="card bg-a mb-3 mt-sm-3 mt-md-3 mt-lg-0 mt-xl-0">
                <div className="card-header">
                    <div className="row pt-2 pb-2 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 col-lg-4">
                            <button className="btn btn-a me-2" onClick={()=>setAddExpenseShow(true)}>
                                <FontAwesomeIcon icon={faAdd}/> Create Expense
                            </button>
                        </div>
                        <div className="col-10 col-md-6 col-lg-5 col-xl-4 pt-3 pt-md-0">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search"
                                       aria-label="Recipient's username" aria-describedby="search-btn"
                                       onChange={(e)=>setSearch(e.target.value)}
                                       onKeyUp={(e)=> {
                                           e.key === 'Enter' && handleSearch();
                                           search === '' && getExpenses();
                                       }}
                                />
                                <button className="btn btn-a" type="button" id="search-btn" onClick={handleSearch}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </button>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 col-lg-3 col-xl-4 pt-3 pt-md-0">
                            {/*{(page === 1 && search === '') && <div className="d-flex justify-content-end">*/}
                            {/*    <button className="btn btn-a" type="button" id="predictions-btn">*/}
                            {/*        <FontAwesomeIcon icon={faChartColumn} className="me-2"/> Statistics*/}
                            {/*    </button>*/}
                            {/*</div>}*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    loading ?
                        <div className="card-body d-flex align-items-center justify-content-center">
                            <Loader className="mt-5" zoom="5"/>
                        </div> :
                        expenses.map(expense => (
                            <div key={expense.id} className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                                <div className="card bg-a mt-3">
                                    <div className="card-header color-a">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-2"></div>
                                            <div className="col-8">
                                                <h5 className="card-title text-center">{expense.title}</h5>
                                            </div>
                                            <div className="col-2">
                                                <div className="dropdown float-end">
                                                    <button className="btn btn-b dropdown-toggle" type="button"
                                                            id="actionMenu"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-a"
                                                        aria-labelledby="actionMenu">
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setEditExpenseValues({
                                                                    id: expense.id,
                                                                    ...expense,
                                                                    deposits: expense.deposits ? JSON.parse(expense.deposits) : {},
                                                                });
                                                                setEditExpenseShow(true);
                                                            }}>Edit
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setDeleteId(expense.id);
                                                                setDeleteExpenseShow(true);
                                                            }}>Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">{expense.comment}</p>
                                        <table className="table table-borderless color-a">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Budget</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{expense.budget}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Created At</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{moment(expense.created_at).format('DD MMMM YYYY')}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Updated At</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{expense.updated_at && moment(expense.updated_at).format('DD MMMM YYYY')}</p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="mt-3 d-flex align-items-center justify-content-center">
                                            <NavLink to={`${expense.id}`} className="btn btn-b" >
                                                <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
                                                Open
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
            <div className="mt-3">
                {
                    !loading &&
                    <nav className="custom-pagination">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => setPage(page - 1)}>
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                </button>
                            </li>
                            {
                                Array.from(Array(totalPage).keys()).map((item, index) => {
                                    return (
                                        <li key={index} className={`page-item ${page === item + 1 ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setPage(item + 1)}>{item + 1}</button>
                                        </li>
                                    );
                                })
                            }
                            <li className={`page-item ${page === totalPage ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => setPage(page + 1)}>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </button>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
        </div>
    );
};

export default Expenses;