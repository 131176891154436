import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {UserContext} from "../../../../App";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd, faChartColumn,
    faChevronLeft,
    faChevronRight,
    faEdit, faFileAlt,
    faLightbulb,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../Components/Utilities/Loader";
import moment from "moment/moment";
import {betweenTwoTimestamp} from "../../../../Helper/helper";
import AddRecordModal from "./AddRecordModal";
import EditRecordModal from "./EditRecordModal";
import DeleteRecordModal from "./DeleteRecordModal";

const Sex = () => {
    const {wifeId} = useParams();
    const [user] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [editRecord, setEditRecord] = useState({});
    const limit = 100;
    const loadRecords = () => {
        records.length <= 0 && setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/wife/sex/get-records/${wifeId}/${limit}/${page}`, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            setLoading(false);
            setRecords(response.data.data);
            setTotalRecords(response.data.total);
        }).catch(error => {
            console.log(error);
        });
    }
    const handleSearch = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/prp/wife/sex/search-records/${wifeId}/${limit}/${page}`, {
            search: search
        }, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            setRecords(response.data.data);
            setTotalRecords(response.data.total);
        }).catch(error => {
            console.log(error);
        });
    }
    useEffect(() => {
        loadRecords();
    }, [page]);
    useEffect(() => {
        setTotalPage(Math.ceil(totalRecords / limit));
    }, [totalRecords])
    return (
        <div>
            <AddRecordModal
                show={showAddDialog}
                setShow={setShowAddDialog}
                wifeId={wifeId}
                loadRecords={loadRecords}
            />
            <EditRecordModal
                show={showEditDialog}
                setShow={setShowEditDialog}
                loadRecords={loadRecords}
                setEditRecord={setEditRecord}
                editRecord={editRecord}
            />
            <DeleteRecordModal
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
                recordId={deleteId}
                loadRecords={loadRecords}
            />
            <div className="card bg-a mb-3">
                <div className="card-body mb-3">
                    <div className="row pt-2 pb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 col-lg-4">
                            <button className="btn btn-a me-2" onClick={()=>setShowAddDialog(true)}>
                                <FontAwesomeIcon icon={faAdd}/> Add Record
                            </button>
                        </div>
                        <div className="col-10 col-md-6 col-lg-5 col-xl-4 pt-3 pt-md-0">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search"
                                       aria-label="Recipient's username" aria-describedby="search-btn"
                                       onChange={(e)=>setSearch(e.target.value)}
                                       onKeyUp={(e)=> {
                                           e.key === 'Enter' && handleSearch();
                                           search === '' && loadRecords();
                                       }}
                                />
                                <button className="btn btn-a" type="button" id="search-btn" onClick={handleSearch}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </button>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 col-lg-3 col-xl-4 pt-3 pt-md-0">
                            <div className="d-flex justify-content-end">
                                <NavLink to="statistics" className="btn btn-a">
                                    <FontAwesomeIcon icon={faChartColumn} className="me-2"/> Statistics
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {loading ?
                        <div className="p-5 d-flex align-items-center justify-content-center">
                            <Loader zoom="2" />
                        </div> : records.length > 0 ?
                            <div className="table-responsive">
                                <table className="table session-table text-white">
                                    <thead>
                                    <tr className="bg-b">
                                        <th scope="col" className="text-white">#</th>
                                        <th className="text-white">Date</th>
                                        <th className="text-white">Time</th>
                                        <th className="text-white">Rating</th>
                                        <th className="text-white">Comment</th>
                                        <th className="text-white text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        records.map((record, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{totalRecords - ((page - 1) * limit) - index}</th>
                                                    <td>{moment.unix(record.date).format('YYYY-MM-DD')}</td>
                                                    <td>{record.time}</td>
                                                    <td>{record.rating}</td>
                                                    <td>{record.comment}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <button className="btn btn-b me-2" onClick={()=>{
                                                                setShowEditDialog(true);
                                                                setEditRecord({...record, date: moment.unix(record.date).format('YYYY-MM-DD')});
                                                            }}>
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </button>
                                                            <button className="btn btn-b" onClick={()=> {
                                                                setDeleteId(record.id);
                                                                setShowDeleteDialog(true)
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                                <nav className="custom-pagination">
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => setPage(page - 1)}>
                                                <FontAwesomeIcon icon={faChevronLeft}/>
                                            </button>
                                        </li>
                                        {
                                            Array.from(Array(totalPage).keys()).map((item, index) => {
                                                return (
                                                    <li key={index} className={`page-item ${page === item + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => setPage(item + 1)}>{item + 1}</button>
                                                    </li>
                                                );
                                            })
                                        }
                                        <li className={`page-item ${page === totalPage ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => setPage(page + 1)}>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            :
                            <div className="p-5 d-flex align-items-center justify-content-center color-a">
                                <div className="text-center">
                                    <FontAwesomeIcon icon={faFileAlt} size="5x"/>
                                    <h3 className="mt-3">No Records Found</h3>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Sex;