import moment from "moment";

export const random = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const firstLetterCapitalized = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const dataUrlToFile = (dataUrl) => {
    let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let extension = mime.split('/')[1];
    return new File([u8arr], Math.random().toString(36).substring(2) + '.' + extension, {type:mime});
}

export const timestampToToday = (timestamp) => {
    const timestampInMs = timestamp * 1000;
    const timeElapsedInMs = Date.now() - timestampInMs;
    const yearsElapsed = Math.floor(timeElapsedInMs / (1000 * 60 * 60 * 24 * 365.25));
    const remainingTimeElapsedInMs = timeElapsedInMs % (1000 * 60 * 60 * 24 * 365.25);
    const daysElapsed = Math.floor(remainingTimeElapsedInMs / (1000 * 60 * 60 * 24));
    let remainingDaysElapsedInMs = remainingTimeElapsedInMs % (1000 * 60 * 60 * 24);
    let monthsElapsed = 0;
    let daysInMonth = 0;
    for (let month = 0; month < 12; month++) {
        daysInMonth = new Date(yearsElapsed, month + 1, 0).getDate();
        if (remainingDaysElapsedInMs >= daysInMonth * 1000 * 60 * 60 * 24) {
            monthsElapsed++;
            remainingDaysElapsedInMs -= daysInMonth * 1000 * 60 * 60 * 24;
        } else {
            break;
        }
    }
    let timeElapsedString = "";
    if (yearsElapsed > 0) {
        timeElapsedString += `${yearsElapsed} year${yearsElapsed > 1 ? "s" : ""} `;
    }
    if (monthsElapsed > 0) {
        timeElapsedString += `${monthsElapsed} month${monthsElapsed > 1 ? "s" : ""} `;
    }
    if (daysElapsed > 0) {
        timeElapsedString += `${daysElapsed} day${daysElapsed > 1 ? "s" : ""} `;
    }
    return timeElapsedString.trim();
}

export const betweenTwoTimestamp = (start, end) => {
    const timestampInMs = start * 1000;
    const timeElapsedInMs = (end * 1000) - timestampInMs;
    const yearsElapsed = Math.floor(timeElapsedInMs / (1000 * 60 * 60 * 24 * 365.25));
    const remainingTimeElapsedInMs = timeElapsedInMs % (1000 * 60 * 60 * 24 * 365.25);
    const daysElapsed = Math.floor(remainingTimeElapsedInMs / (1000 * 60 * 60 * 24));
    let remainingDaysElapsedInMs = remainingTimeElapsedInMs % (1000 * 60 * 60 * 24);
    let monthsElapsed = 0;
    let daysInMonth = 0;
    for (let month = 0; month < 12; month++) {
        daysInMonth = new Date(yearsElapsed, month + 1, 0).getDate();
        if (remainingDaysElapsedInMs >= daysInMonth * 1000 * 60 * 60 * 24) {
            monthsElapsed++;
            remainingDaysElapsedInMs -= daysInMonth * 1000 * 60 * 60 * 24;
        } else {
            break;
        }
    }
    let timeElapsedString = "";
    if (yearsElapsed > 0) {
        timeElapsedString += `${yearsElapsed} year${yearsElapsed > 1 ? "s" : ""} `;
    }
    if (monthsElapsed > 0) {
        timeElapsedString += `${monthsElapsed} month${monthsElapsed > 1 ? "s" : ""} `;
    }
    if (daysElapsed > 0) {
        timeElapsedString += `${daysElapsed} day${daysElapsed > 1 ? "s" : ""} `;
    }
    return timeElapsedString.trim();
}

export const maxInArray = (array, string) => {
    let max = 0;
    let maxString = "";
    array.forEach((item) => {
        if (item[string] > max) {
            max = item[string];
            maxString = item;
        }
    });
    return maxString;
}

export const minInArray = (array, string) => {
    let min = 0;
    let minString = "";
    array.forEach((item) => {
        if (item[string] < min) {
            min = item[string];
            minString = item;
        }
    });
    return minString;
}