import React, {useContext} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {getUserAvatar, isUserLoggedIn} from "../../../Helper/auth";
import {UserContext} from "../../../App";
import axios from "axios";
import SessionClock from "../../Utilities/SessionClock";
const Header = () => {
    const [user,setUser] = useContext(UserContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        axios.delete(process.env.REACT_APP_API_BASE_URL + '/auth/logout', {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            localStorage.removeItem('loginData');
            setUser({});
            window.location.replace('/auth/login');
        }).catch(err => {
            localStorage.removeItem('loginData');
            setUser({});
            window.location.replace('/auth/login');
        });
    }
    return (
        <nav id="header" className="navbar navbar-expand-sm navbar-dark pe-lg-4 mb-2">
          <div className="container-fluid">
              <button className="navbar-toggler ms-auto"
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbar" aria-controls="navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
              <div className="collapse navbar-collapse" id="navbar">
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
                      <li className="nav-item">
                          <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/">Home</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/about">About</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/projects">Projects</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/blog">Blog</NavLink>
                      </li>
                      <li className="nav-item">
                          <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/live">Live</NavLink>
                      </li>
                      {/*<li className="nav-item">*/}
                      {/*    <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/contact">Contact</NavLink>*/}
                      {/*</li>*/}
                      {!isUserLoggedIn() &&
                          <li className="nav-item">
                              <NavLink className={navInfo=>navInfo.isActive ? 'nav-link active-page' : 'nav-link'} to="/auth/login">Login</NavLink>
                          </li>
                      }
                      {isUserLoggedIn() &&
                          <li className="nav-item">
                              <div className="dropdown dropcenter ms-2">
                                  <button className="btn btn-b" data-bs-toggle="dropdown" aria-expanded="false">
                                      <div className="d-flex align-items-center">
                                          <div className="d-inline">
                                              {user.name}
                                              <br/>
                                              <SessionClock />
                                          </div>
                                          <img src={getUserAvatar()} className="user-image-nav dropdown-toggle" />
                                          <ul className="dropdown-menu dropdown-a">
                                              <li><button className="dropdown-item" onClick={()=>navigate('/dashboard')} >Dashboard</button></li>
                                              <li><button className="dropdown-item" onClick={()=>navigate('/dashboard/profile')} >Profile</button></li>
                                              <li><button className="dropdown-item text-danger" onClick={handleLogout}>Logout</button></li>
                                          </ul>
                                      </div>
                                  </button>
                              </div>
                          </li>
                      }
                  </ul>
              </div>
          </div>
        </nav>
    );
};

export default Header;