import React, {useContext, useEffect, useRef, useState} from 'react';
import {getUserAvatar, getUserData} from "../../../../Helper/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCheck, faClose} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../Utilities/LoadingButton";
import {UserContext} from "../../../../App";
import axios from "axios";
import {toast} from "react-toastify";
const ImageSection = () => {
    const [user, setUser] = useContext(UserContext);
    const [image, setImage] = useState(null);
    const imageRef = useRef();
    const imageUploadAreaRef = useRef();
    const editFileRef = useRef();
    useEffect(() => {
        if(image){
            const reader = new FileReader();
            reader.onload = () => {
                imageRef.current.src = reader.result;
            }
            reader.readAsDataURL(image);
            imageUploadAreaRef.current.classList.remove('d-none');
        }else{
            imageRef.current.src = getUserAvatar();
            imageUploadAreaRef.current.classList.add('d-none');
        }
    },[image]);
    const uploadImage = (setLoading) => {
        if(image){
            setLoading(true);
            let formData = new FormData();
            formData.append('id', user.id);
            formData.append('avatar', image);
            axios.post(process.env.REACT_APP_API_BASE_URL + '/profile/update-avatar', formData, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).then(response => {
                setLoading(false);
                if(response.data.error){
                    toast(response.data.error, {type: 'error'});
                }else {
                    let userData = JSON.parse(localStorage.getItem('loginData'));
                    userData.user.meta = userData.user.meta ? userData.user.meta : {};
                    userData.user.meta.image_url = response.data.image_url;
                    localStorage.setItem('loginData', JSON.stringify(userData));
                    setUser(getUserData());
                    imageUploadAreaRef.current.classList.add('d-none');
                    toast('Image uploaded successfully', {type: 'success'});
                }
            }).catch(err => {
                setLoading(false);
                toast('Something went wrong', {type: 'error'});
            });
        }
    }
    return (
        <div>
            <div className="d-flex mb-3 align-items-center justify-content-center pt-3">
                <img ref={imageRef} src={getUserAvatar()} alt="profile" className="img-thumbnail user-image-profile" />
                <button onClick={()=>editFileRef.current.click()} className="btn btn-a btn-sm user-image-profile-upload-btn" >
                    <FontAwesomeIcon icon={faCamera} className="text-white" />
                </button>
                <input type="file" ref={editFileRef} className="d-none" onChange={e=>setImage(e.target.files[0])} />
            </div>
            <div ref={imageUploadAreaRef} className="d-flex mb-3 justify-content-center gap-2 mt-2 d-none">
                <button className="btn btn-b" onClick={()=>setImage(null)}>
                    <FontAwesomeIcon icon={faClose} className="color-a" />
                </button>
                <LoadingButton text={<FontAwesomeIcon icon={faCheck} className="color-a" />} onClick={uploadImage} />
            </div>
        </div>
    );
};

export default ImageSection;