import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {betweenTwoTimestamp} from "../../../../Helper/helper";
import moment from "moment/moment";

const PeriodStatisticsModal = ({show, setShow, records}) => {
    const [periodStatistics, setPeriodStatistics] = useState({
        averagePeriod: '',
        averageCycle: '',
        nextPeriodStart1: '',
        nextPeriodEnd1: '',
        nextPeriodStart2: '',
        nextPeriodEnd2: '',
        firstSafeDayStart: '',
        firstSafeDayEnd: '',
        secondSafeDayStart: '',
        secondSafeDayEnd1: '',
        secondSafeDayEnd2: '',
    });
    useEffect(() => {
        let menstrualCycles = [];
        let menstrualPeriods = [];
        records.forEach((record, index) => {
            if (index < 6) {
                const cycle = betweenTwoTimestamp(records[index + 1]?.end_date, record.start_date);
                let cycleInt = parseInt(cycle.replace(' days', ''));
                !isNaN(cycleInt) && menstrualCycles.push(cycleInt);
            }
        });
        menstrualPeriods = records.map((record,index) => {
            if(index < 6){
                let length = betweenTwoTimestamp(record.start_date, record.end_date);
                return parseInt(length.replace(' days', ''));
            }else {
                return 0;
            }
        });
        menstrualPeriods = menstrualPeriods.filter((item) => item !== 0);
        const lastPeriodStart = moment.unix(records[0]?.start_date).format('D MMMM, YYYY');
        const lastPeriodEnd = moment.unix(records[0]?.end_date).format('D MMMM, YYYY');
        const maxCycle = Math.max(...menstrualCycles);
        const minCycle = Math.min(...menstrualCycles);
        const averageCycle = Math.round(menstrualCycles.reduce((a, b) => a + b, 0) / menstrualCycles.length);
        const maxPeriod = Math.max(...menstrualPeriods);
        const minPeriod = Math.min(...menstrualPeriods);
        const averagePeriod = Math.round(menstrualPeriods.reduce((a, b) => a + b, 0) / menstrualPeriods.length);
        const nextPeriodStart1 = moment.unix(records[0]?.start_date).add(minCycle, 'days').format('D MMMM, YYYY');
        const nextPeriodEnd1 = moment(nextPeriodStart1).add(minPeriod, 'days').format('D MMMM, YYYY');
        const nextPeriodStart2 = moment.unix(records[0]?.start_date).add(maxCycle, 'days').format('D MMMM, YYYY');
        const nextPeriodEnd2 = moment(nextPeriodStart2).add(maxPeriod, 'days').format('D MMMM, YYYY');
        const firstSafeDay = minCycle - 18;
        const lastSafeDay = maxCycle - 11;
        const firstSafeDayEnd = moment(lastPeriodStart).add(firstSafeDay, 'days').format('D MMMM, YYYY');
        const firstSafeDayStart = moment(lastPeriodEnd).format('D MMMM, YYYY');
        const secondSafeDayStart = moment(lastPeriodStart).add(lastSafeDay, 'days').format('D MMMM, YYYY');
        const secondSafeDayEnd1 = moment(nextPeriodStart1).format('D MMMM, YYYY');
        const secondSafeDayEnd2 = moment(nextPeriodStart2).format('D MMMM, YYYY');
        setPeriodStatistics({
            averagePeriod,
            averageCycle,
            nextPeriodStart1,
            nextPeriodEnd1,
            nextPeriodStart2,
            nextPeriodEnd2,
            firstSafeDayStart,
            firstSafeDayEnd,
            secondSafeDayStart,
            secondSafeDayEnd1,
            secondSafeDayEnd2,
        });
    }, [records]);

    return (
        <Modal className="modal-lg" centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient d-flex flex-column align-items-center justify-content-center">
                    <h2 className="color-a">Statistics</h2>
                    <p className="color-b">(based on last 6 period record)</p>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                            <td className="color-a">Average Cycle</td>
                            <td className="color-a">:</td>
                            <td className="color-b">{periodStatistics.averageCycle} days</td>
                        </tr>
                        <tr>
                            <td className="color-a">Average Period</td>
                            <td className="color-a">:</td>
                            <td className="color-b">{periodStatistics.averagePeriod} days</td>
                        </tr>
                        <tr>
                            <td className="color-a">Next Period Start</td>
                            <td className="color-a">:</td>
                            <td className="color-b">{periodStatistics.nextPeriodStart1} to {periodStatistics.nextPeriodStart2}</td>
                        </tr>
                        <tr>
                            <td className="color-a">Next Period End</td>
                            <td className="color-a">:</td>
                            <td className="color-b">{periodStatistics.nextPeriodEnd1} to {periodStatistics.nextPeriodEnd2}</td>
                        </tr>
                        <tr>
                            <td className="color-a">Next Safe Days</td>
                            <td className="color-a">:</td>
                            <td className="color-b">{periodStatistics.firstSafeDayStart} to {periodStatistics.firstSafeDayEnd}<br/>and {periodStatistics.secondSafeDayStart} to {periodStatistics.secondSafeDayEnd1} or {periodStatistics.secondSafeDayEnd2}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>OK</button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default PeriodStatisticsModal;