import React, {useContext, useEffect, useState} from 'react';
import Loader from "../../../Components/Utilities/Loader";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../../../App";
import axios from "axios";
import {toast} from "react-toastify";
import AddWifeModal from "./AddWifeModal";
import UserAvatar from "../../../Assets/Images/user-avatar.png";
import moment from "moment";
import EditWifeModal from "./EditWifeModal";
import DeleteWifeModal from "./DeleteWifeModal";
import {timestampToToday} from "../../../Helper/helper";

const Wives = () => {
    const [user] = useContext(UserContext);
    const [wives, setWives] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addWifeShow, setAddWifeShow] = useState(false);
    const [editWifeShow, setEditWifeShow] = useState(false);
    const [editWifeValues, setEditWifeValues] = useState({});
    const [deleteWifeShow, setDeleteWifeShow] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const getWives = () => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/prp/wife/get-wives', {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }).then(res => {
            if (!res.data.error) {
                setWives(res.data.data);
                setLoading(false);
            }else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            toast('Something went wrong!\nData could not be loaded from the server.', {type: 'error'});
        });
    }
    useEffect(() => {
        getWives();
    }, []);
    return (
        <div className="container">
            <AddWifeModal
                show={addWifeShow}
                setShow={setAddWifeShow}
                loadWives={getWives}
            />
            <EditWifeModal
                show={editWifeShow}
                setShow={setEditWifeShow}
                editValue={editWifeValues}
                setEditValue={setEditWifeValues}
                loadWives={getWives}
            />
            <DeleteWifeModal
                show={deleteWifeShow}
                setShow={setDeleteWifeShow}
                deleteId={deleteId}
                loadWives={getWives}
            />
            <div className="row">
                {
                    loading ?
                        <div className="card-body d-flex align-items-center justify-content-center">
                            <Loader className="mt-5" zoom="5"/>
                        </div> :
                        wives.map(wife => (
                            <div key={wife.id} className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                                <div className="card bg-a mt-3 mt-md-3 mt-lg-0">
                                    <div className="card-header color-a">
                                        <div className="row">
                                            <div className="col-2"></div>
                                            <div className="col-8">
                                            </div>
                                            <div className="col-2">
                                                <div className="dropdown float-end">
                                                    <button className="btn btn-b dropdown-toggle" type="button"
                                                            id="actionMenu"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-a"
                                                        aria-labelledby="actionMenu">
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setEditWifeValues({
                                                                    ...wife,
                                                                    id: wife.id,
                                                                    dob: moment.unix(wife.dob).format('YYYY-MM-DD'),
                                                                    marriage_date: moment.unix(wife.marriage_date).format('YYYY-MM-DD'),
                                                                    death_date: wife.is_alive == '0' ? moment.unix(wife.death_date).format('YYYY-MM-DD') : '',
                                                                    divorce_date: wife.is_divorced == '1' ? moment.unix(wife.divorce_date).format('YYYY-MM-DD') : '',
                                                                });
                                                                setEditWifeShow(true);
                                                            }}>Edit
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setDeleteId(wife.id);
                                                                setDeleteWifeShow(true);
                                                            }}>Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-3 align-items-center justify-content-center">
                                            <img src={wife.image ? wife.image : UserAvatar} alt="profile" className="img-thumbnail user-image-profile" />
                                        </div>
                                        <h5 className="card-title text-center">{wife.name}</h5>
                                        <p className="card-text text-center"><span style={{fontSize: '12px'}}>{timestampToToday(wife.dob)} Old</span></p>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-borderless color-a">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Status</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">
                                                        {
                                                            wife.is_alive == '1' ?
                                                                (
                                                                    wife.is_divorced == '1' ?
                                                                        <span className="badge bg-danger">Ex</span> :
                                                                        <span className="badge bg-success">Current</span>
                                                                ) : <span className="badge bg-danger">Passed Away</span>
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Date of Birth</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{moment.unix(wife.dob).format('DD-MM-YYYY')}</p>
                                                </td>
                                            </tr>
                                            {
                                                wife.is_alive == '0' &&
                                                <tr>
                                                    <td>
                                                        <p className="card-text">Date of Death</p>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        <p className="card-text">{moment.unix(wife.death_date).format('DD-MM-YYYY')}</p>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>
                                                    <p className="card-text">Marriage Date</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{moment.unix(wife.marriage_date).format('DD-MM-YYYY')}</p>
                                                </td>
                                            </tr>
                                            {
                                                wife.is_divorced == '1' &&
                                                <tr>
                                                    <td>
                                                        <p className="card-text">Divorce Date</p>
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                        <p className="card-text">{moment.unix(wife.divorce_date).format('DD-MM-YYYY')}</p>
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                        <div className="mt-3 d-flex align-items-center justify-content-center">
                                            <NavLink to={`${wife.id}/period`} className="btn btn-b" >
                                                <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
                                                Open
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                }
                {
                    !loading &&
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div className="card bg-a mt-3 mt-md-3 mt-lg-0 meter-box">
                            <div className="card-body d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faPlusCircle} className="add-btn" size={"5x"} onClick={() => setAddWifeShow(true)}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Wives;