import React, {createContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js'
import '../src/Assets/Styles/Main.css';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./Components/Guest/Header/Header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Components/Guest/Home/Home";
import About from "./Components/Guest/About/About";
import Projects from "./Components/Guest/Projects/Projects";
import Contact from "./Components/Guest/Contact/Contact";
import Blog from "./Components/Guest/Blog/Blog";
import NotFound from "./Components/Guest/404/NotFound";
import Footer from "./Components/Guest/Footer/Footer";
// import Particles from "react-particles";
// import ParticleConfig from "./Data/ParticleConfig";
import {getUserData, isUserAdmin} from "./Helper/auth";
import Login from "./Components/Guest/Auth/Login";
import VerifyAccount from "./Components/Guest/Auth/VerifyAccount";
import ForgotPassword from "./Components/Guest/Auth/ForgotPassword";
import Register from "./Components/Guest/Auth/Register";
import AdminDashboard from "./Components/Admin/Dashboard/Dashboard";
import GuestDashboard from "./Components/Guest/Dashboard/Dashboard";
import PrivateRoute from "./Components/Utilities/PrivateRoute";
import {ToastContainer} from "react-toastify";
import axios from "axios";
import AdminRoute from "./Components/Utilities/AdminRoute";
import PersonalResourcePlanner from "./Applications/PRP/PersonalResourcePlanner";
import Live from "./Components/Guest/Live/Live";
import Lives from "./Components/Guest/Live/Lives";

export const UserContext = createContext([]);
const App = () => {
    const [user, setUser] = useState(getUserData());
    useEffect(() =>{
        axios.get(process.env.REACT_APP_API_BASE_URL + '/profile/user/'+user.id, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            if(!response.data.error){
                const data = response.data.user;
                let userData = JSON.parse(localStorage.getItem('loginData'));
                userData.user.meta = data.meta ? JSON.parse(data.meta) : {};
                userData.user.name = data.name;
                localStorage.setItem('loginData', JSON.stringify(userData));
                setUser(getUserData());
            }
        }).catch(err => {
            console.log(err);
        });
    },[])
    return (
        <UserContext.Provider value={[user, setUser]}>
            <ToastContainer position="top-center" />
            <BrowserRouter>
                {/*<Particles*/}
                {/*    className="background"*/}
                {/*    options={ParticleConfig}*/}
                {/*/>*/}
                <Header/>
                   <Routes>
                       <Route path="/" element={<Home/>} />
                       <Route path="/about" element={<About/>} />
                       <Route path="/projects" element={<Projects/>} />
                       <Route path="/blog" element={<Blog/>} />
                       <Route path="/live/*" element={<Lives/>} />
                       <Route path="/contact" element={<Contact/>} />
                       <Route path='/auth' >
                           <Route path='register' element={<Register/>} />
                           <Route path='verify-account' element={<VerifyAccount/>} />
                           <Route path='login' element={<Login/>} />
                           <Route path='forgot-password' element={<ForgotPassword/>} />
                       </Route>
                       <Route path="/dashboard/*" element={isUserAdmin() ?<AdminRoute><AdminDashboard/></AdminRoute> : <PrivateRoute><GuestDashboard/></PrivateRoute>} />
                       <Route path="/prp/*" element={<AdminRoute><PersonalResourcePlanner/></AdminRoute>} />
                       <Route path="*" element={<NotFound/>} />
                   </Routes>
                <Footer/>
            </BrowserRouter>
        </UserContext.Provider>
    );
}
export default App;