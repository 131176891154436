import React, {useContext, useRef} from 'react';
import axios from "axios";
import {random} from "../../../Helper/helper";
import dummyImage from "../../../Assets/Images/dummy_1.png";
import {UserContext} from "../../../App";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const CreateLive = () => {
    const [user] = useContext(UserContext);
    let navigation = useNavigate();
    const displayRef = useRef(null);
    const imageRef = useRef(null);
    const keyRef = useRef(null);
    const linkRef = useRef(null);
    const titleRef = useRef(null);
    const slugRef = useRef(null);
    const descriptionRef = useRef(null);
    let enable = 1;
    let loginRequired = 0;
    const handleSelect = event => {
        displayRef.current.src = URL.createObjectURL(event.target.files[0]);
    }
    const handleKey = () => {
        keyRef.current.value = random(32);
    }
    const setEnable = event => {
        enable = event.target.value;
    }
    const setLoginRequired = event => {
        loginRequired = event.target.value;
    }
    const generateSlug = () => {
        const title = titleRef.current.value;
        slugRef.current.value = title.toLowerCase().replace(/ /g, '-');
    }
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('image', imageRef.current.files[0]);
        formData.append('key', keyRef.current.value);
        formData.append('link', linkRef.current.value);
        formData.append('title', titleRef.current.value);
        formData.append('slug', slugRef.current.value);
        formData.append('description', descriptionRef.current.value);
        formData.append('is_active', enable);
        formData.append('login_required', loginRequired);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/live/moderation/create`, formData, {
            headers: {
                "Authorization": `Bearer ${user.token}`
            }
        }).then(r=>{
            if (r.data.error){
                toast(r.data.error, {type: 'error'});
            } else {
                toast('Live created successfully', {type: 'success'});
                navigation('/dashboard/live');
            }
        });

    }
    return (
        <div className="container">
            <div className="card bg-a">
                <div className="card-header pt-3">
                    <h2 className="color-a">New Live</h2>
                </div>
                <div className="card-body">
                    <div className="row d-flex align-items-end">
                        <div className="col-md-2 mb-3">
                            <img ref={displayRef} className="img img-thumbnail" src={dummyImage} alt=""/>
                        </div>
                        <div className="col-md-4 mb-3">
                            <input ref={imageRef} onChange={handleSelect} type="file" className="form-control"/>
                        </div>
                        <div className="col-md-3 mb-3">
                            <select className="form-control" onChange={setLoginRequired}>
                                <option className="form-control" value={0} >Login Not Required</option>
                                <option className="form-control" value={1} >Login Required</option>
                            </select>
                        </div>
                        <div className="col-md-3 mb-3">
                            <select className="form-control" onChange={setEnable}>
                                <option className="form-control" value={1} >Enable</option>
                                <option className="form-control" value={0} >Disable</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mb-3">
                            <input ref={keyRef} type="text" className="form-control" placeholder="Key"/>
                        </div>
                        <div className="col-md-2 mb-3">
                            <button className="btn btn-b float-end" onClick={handleKey}>Generate</button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <input ref={linkRef} type="url" className="form-control" placeholder="Link" defaultValue={'https://live.sajjadamin.com/hls'}/>
                    </div>
                    <div className="mb-3">
                        <input ref={titleRef} type="text" className="form-control" placeholder="Title" onChange={generateSlug}/>
                    </div>
                    <div className="mb-3">
                        <input ref={slugRef} type="text" className="form-control" placeholder="Slug"/>
                    </div>
                    <div className="mb-3">
                        <textarea ref={descriptionRef} rows="10" className="form-control" placeholder="Description"></textarea>
                    </div>
                    <button onClick={handleSubmit} className="btn btn-b">Create</button>
                </div>
            </div>
        </div>
    );
};

export default CreateLive;