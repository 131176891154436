import React from 'react';
import {Route, Routes} from "react-router-dom";
import Live from "./Live";
import CreateLive from "./CreateLive";
import UpdateLive from "./UpdateLive";

const Lives = () => {
    return (
        <div>
            <Routes>
                <Route path={'/'} element={<Live/>} />
                <Route path={'/:slug'} element={<UpdateLive/>} />
                <Route path={'create'} element={<CreateLive/>} />
            </Routes>
        </div>
    );
};

export default Lives;