import React, {useContext} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../../Components/Utilities/LoadingButton";
import axios from "axios";
import {toast} from "react-toastify";
import {UserContext} from "../../../../App";

const EditRecordModal = ({show, setShow, meterId, editRecord,setEditRecord, loadRecords}) => {
    const [user] = useContext(UserContext);
    const initialRecord = {meter_id:meterId, date: '', amount: 0, payment_method: 'POS', comment: ''};

    const handleEditChange = (e) => {
        setEditRecord({...editRecord, [e.target.id]: e.target.value});
    }
    const handleUpdate = (setLoading, event, data) => {
        if(editRecord.date !== '' && editRecord.amount !== 0 && editRecord.id) {
            setLoading(true);
            axios.put(process.env.REACT_APP_API_BASE_URL + '/prp/utility-bills/electricity/update-record', editRecord, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).then(response => {
                setLoading(false);
                if(response.data.error){
                    toast(response.data.error, {type: 'error'});
                }else{
                    loadRecords();
                    toast('Record updated successfully', {type: 'success'});
                    setShow(false);
                    setEditRecord(initialRecord);
                }
            }).catch(error => {
                toast('Something went wrong', {type: 'error'});
                setLoading(false);
            });
        }else{
            toast('Please fill up all the fields', {type: 'error'});
        }
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faEdit}/> Edit Record</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="date" className="form-label">Date</label>
                        <input type="datetime-local" className="form-control bg-b text-white" id="date" onChange={handleEditChange} defaultValue={
                            !isNaN(editRecord.date) ? new Date(editRecord.date * 1000).toISOString().slice(0, 19) : editRecord.date
                        }/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="amount" className="form-label">Amount</label>
                        <input type="number" className="form-control bg-b text-white" id="amount" onChange={handleEditChange} value={editRecord.amount}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="payment_method" className="form-label">Payment Method</label>
                        <select className="form-select bg-b text-white" id="payment_method" onChange={handleEditChange} defaultValue={editRecord.payment_method}>
                            <option value="POS">POS</option>
                            <option value="bKash">bKash</option>
                            <option value="Nagad">Nagad</option>
                            <option value="Rocket">Rocket</option>
                            <option value="Bank">Bank</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control bg-b text-white" id="comment" rows="3" onChange={handleEditChange} defaultValue={editRecord.comment}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Update" onClick={handleUpdate}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default EditRecordModal;