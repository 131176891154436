import React, {useContext, useEffect, useRef} from 'react';
import { Routes, Route, NavLink} from "react-router-dom";
import Blog from "../../Guest/Blog/Blog";
import Projects from "../../Guest/Projects/Projects";
import Live from "../Lives/Live";
import Profile from "../../Guest/Dashboard/Profile/Profile";
import Lives from "../Lives/Lives";
// import Live from "../Lives/Live";
// import UpdateLive from "../Lives/UpdateLive";
// import CreateLive from "../Lives/CreateLive";

const Dashboard = () => {
    const dashboardContainer = useRef(null);
    useEffect(()=>{
        const header = document.getElementById('header');
        const footer = document.getElementById('footer');
        const extraHeight = header.offsetHeight + footer.offsetHeight;
        if(dashboardContainer.current){
            dashboardContainer.current.style.minHeight = `${window.innerHeight - extraHeight - 10}px`;
        }
    });
    return (
        <div className="container-fluid">
            <div className="dashboard-wrapper">
                <div className="dashboard-sidebar bg-glass">
                    <ul className="list-group">
                        <NavLink to="/dashboard" className={(navInfo)=> {
                            let pathParams = window.location.pathname.split('/');
                            let match = pathParams[pathParams.length-1] === 'dashboard';
                            return match ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'
                        }} >
                            <li className="list-group-item nav-glass">Dashboard</li>
                        </NavLink>
                        <NavLink to="/dashboard/profile" className={navInfo=>navInfo.isActive ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'} >
                            <li className="list-group-item nav-glass">Profile</li>
                        </NavLink>
                        <NavLink to={'/dashboard/project'} className={navInfo=>navInfo.isActive ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'}>
                            <li className="list-group-item nav-glass">Project</li>
                        </NavLink>
                        <NavLink to={'/dashboard/blog'} className={navInfo=>navInfo.isActive ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'}>
                            <li className="list-group-item nav-glass">Blog</li>
                        </NavLink>
                        <NavLink to={'/dashboard/live'} className={navInfo=>navInfo.isActive ? 'dash-nav-link dash-nav-link-selected' : 'dash-nav-link'}>
                            <li className="list-group-item nav-glass">Live</li>
                        </NavLink>
                        <hr/>
                        <NavLink to="/prp" className="dash-nav-link" >
                            <li className="list-group-item nav-glass">Personal Resource Planner</li>
                        </NavLink>
                    </ul>
                </div>
                <div ref={dashboardContainer} className="dashboard-content">
                    <Routes>
                        <Route path={'profile'} element={<Profile/>} />
                        <Route path={'live/*'} element={<Lives/>} />
                        <Route path={'project'} element={<Projects/>} />
                        <Route path={'blog'} element={<Blog/>} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;