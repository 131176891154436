import React, {useEffect, useRef} from 'react';
import {NavLink, Route, Routes} from "react-router-dom";
import Period from "./Period/Period";
import Sex from "./Sex/Sex";
import Statistics from "./Sex/Statistics";

const Wife = () => {
    const tabRef = useRef(null);
    useEffect(() => {
        const buttons = [];
        tabRef.current.querySelectorAll('button').forEach(button => {
            buttons.push(button);
        });
        buttons.forEach(button => {
            button.addEventListener('click', () => {
                buttons.forEach(button => {
                    button.classList.remove('btn-tab-active');
                });
                button.classList.add('btn-tab-active');
            });
        });
    }, []);
    return (
        <div className="container">
            <div className="row">
                <nav>
                    <div className="nav nav-tabs mt-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0" role="tablist" ref={tabRef}>
                        <NavLink to="period" className="no-text-decoration">
                            <button className="nav-link btn-tab btn-tab-active">Period</button>
                        </NavLink>
                        <NavLink to="sex" className="no-text-decoration">
                            <button className="nav-link btn-tab">Sex</button>
                        </NavLink>
                    </div>
                </nav>
            </div>
            <div className="row">
                <Routes>
                    <Route path="/*" element={<Period/>}/>
                    <Route path="period" element={<Period/>}/>
                    <Route path="sex" element={<Sex/>}/>
                    <Route path="sex/statistics" element={<Statistics/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default Wife;