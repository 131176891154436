import React, {useContext, useEffect, useState} from 'react';
import {NavLink, Route, Routes} from "react-router-dom";
import axios from "axios";
import {UserContext} from "../../../../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import AddMeterModal from "./AddMeterModal";
import Loader from "../../../../Components/Utilities/Loader";
import {toast} from "react-toastify";
import EditMeterModal from "./EditMeterModal";
import DeleteMeterModal from "./DeleteMeterModal";

const Meters = () => {
    const [user] = useContext(UserContext);
    const initialData = {
        display_serial: 0,
        title: "",
        customer_number: "",
        meter_serial: "",
        comment: ""
    }
    const [meters, setMeters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addMeterShow, setAddMeterShow] = useState(false);
    const [editMeterShow, setEditMeterShow] = useState(false);
    const [editMeterValues, setEditMeterValues] = useState(initialData);
    const [deleteMeterShow, setDeleteMeterShow] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const getMeters = () => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/prp/utility-bills/electricity/get-meters', {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }).then(res => {
            if (!res.data.error) {
                setMeters(res.data.data);
                setLoading(false);
            }else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            toast('Something went wrong!\nData could not be loaded from the server.', {type: 'error'});
        });
    }
    useEffect(() => {
        getMeters();
    }, []);

    return (
        <div>
            <AddMeterModal
                show={addMeterShow}
                setShow={setAddMeterShow}
                totalMeter={meters.length}
                loadMeters={getMeters}
            />
            <EditMeterModal
                show={editMeterShow}
                setShow={setEditMeterShow}
                totalMeter={meters.length}
                editValue={editMeterValues}
                setEditValue={setEditMeterValues}
                loadMeters={getMeters}
            />
            <DeleteMeterModal
                show={deleteMeterShow}
                setShow={setDeleteMeterShow}
                deleteId={deleteId}
                loadMeters={getMeters}
            />
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                    <div className="card bg-a mt-3 meter-box">
                        <div className="card-header color-a">
                            <h5 className="card-title text-center">Timeline</h5>
                        </div>
                        <div className="card-body d-flex align-items-center justify-content-center">
                            <NavLink to="timeline" className="btn btn-b">
                                <FontAwesomeIcon icon={faFolderOpen} size="5x"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
                {
                    loading ?
                        <div className="card-body d-flex align-items-center justify-content-center">
                            <Loader zoom="5"/>
                        </div> :
                        meters.map(meter => (
                            <div key={meter.id} className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                                <div className="card bg-a mt-3 meter-box">
                                    <div className="card-header color-a">
                                        <div className="row">
                                            <div className="col-2"></div>
                                            <div className="col-8">
                                                <h5 className="card-title text-center">{meter.title}</h5>
                                            </div>
                                            <div className="col-2">
                                                <div className="dropdown float-end">
                                                    <button className="btn btn-b dropdown-toggle" type="button"
                                                            id="actionMenu"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-a"
                                                        aria-labelledby="actionMenu">
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setEditMeterValues(meter);
                                                                setEditMeterShow(true);
                                                            }}>Edit
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => {
                                                                setDeleteId(meter.id);
                                                                setDeleteMeterShow(true);
                                                            }}>Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-borderless color-a">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Customer No</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{meter.customer_number}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="card-text">Serial No</p>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                    <p className="card-text">{meter.meter_serial}</p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="mt-3 d-flex align-items-center justify-content-center">
                                            <NavLink to={`${meter.id}`} className="btn btn-b">View Records</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                }
                {/*{*/}
                {/*    !loading &&*/}
                {/*    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">*/}
                {/*        <div className="card bg-a mt-3 meter-box">*/}
                {/*            <div className="card-body d-flex align-items-center justify-content-center">*/}
                {/*                <FontAwesomeIcon icon={faPlusCircle} className="add-btn" size={"5x"} onClick={() => setAddMeterShow(true)}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        </div>
    );
};

export default Meters;