import React, {useState} from 'react';

const LoadingLink = ({text, handleClick}) => {
    const [loading, setLoading] = useState(false);
    return (
        <button onClick={event => handleClick(setLoading,event)} className="loading-link align-items-center justify-content-center">
            <div className="d-flex ">
                <span>{text}</span>
                {loading && <div className="loader"></div>}
            </div>
        </button>
    );
};

export default LoadingLink;