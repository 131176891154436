import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../../Components/Utilities/LoadingButton";
import {UserContext} from "../../../../App";
import axios from "axios";
import {toast} from "react-toastify";

const EditMeterModal = ({show, setShow, totalMeter, editValue, setEditValue, loadMeters}) => {
    const [user] = useContext(UserContext);
    const handleChange = (e) => {
        setEditValue({
            ...editValue,
            [e.target.id]: e.target.value
        });
    }
    const handleEdit = (setLoading) => {
        if(editValue.title !== '' && editValue.customer_number !== '' && editValue.meter_serial !== '' && editValue.display_serial !== '') {
            setLoading(true);
            axios.put(process.env.REACT_APP_API_BASE_URL + '/prp/utility-bills/electricity/update-meter', editValue, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            }).then(res => {
                if (!res.data.error) {
                    toast("Meter updated successfully", {type: "success"});
                    setShow(false);
                    loadMeters();
                }else{
                    toast(res.data.message, {type: "error"});
                }
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
            });
        }else{
            toast("Please fill all the fields", {type: "error"});
        }
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> Edit Meter</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Meter Title</label>
                        <input type="text" className="form-control" id="title" onChange={handleChange} value={editValue.title} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="display_serial" className="form-label">Display Serial</label>
                        <select className="form-select" id="display_serial" onChange={handleChange} defaultValue={editValue.display_serial}>
                            <option value="0">Select Display Serial</option>
                            {
                                Array.from(Array(totalMeter).keys()).map((meter, index) => (
                                    <option key={index} value={index + 1}>{index + 1}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="customer_number" className="form-label">Customer No</label>
                        <input type="text" className="form-control" id="customer_number" onChange={handleChange} value={editValue.customer_number} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="meter_serial" className="form-label">Meter Serial No</label>
                        <input type="text" className="form-control" id="meter_serial" onChange={handleChange} value={editValue.meter_serial} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange} defaultValue={editValue.comment}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Update" onClick={handleEdit}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default EditMeterModal;