import React, {useContext, useEffect, useRef, useState} from 'react';
import {getUserData} from "../../../../Helper/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../Utilities/LoadingButton";
import {UserContext} from "../../../../App";
import axios from "axios";
import ImageSection from "./ImageSection";
import {firstLetterCapitalized} from "../../../../Helper/helper";
import {toast} from "react-toastify";

const InfoSection = () => {
    const [user, setUser] = useContext(UserContext);
    const [info, setInfo] = useState({
        id: user.id,
        name: user.name,
        email: user.email,
        meta: {...user.meta}
    });
    useEffect(() => {
        setInfo({
            id: user.id,
            name: user.name,
            email: user.email,
            meta: {...user.meta}
        });
    },[user]);
    const infoRef = useRef();
    const infoEditRef = useRef();
    const updateInfo = (setLoading) => {
        if(info.name !== ''){
            setLoading(true);
            axios.put(process.env.REACT_APP_API_BASE_URL + '/profile/update-info', {...info},{
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).then(response => {
                setLoading(false);
                if(response.data.error){
                    toast(response.data.error, {type: 'error'});
                }else{
                    let userData = JSON.parse(localStorage.getItem('loginData'));
                    userData.user.name = info.name;
                    userData.user.meta = userData.user.meta ? userData.user.meta : {};
                    userData.user.meta = {...info.meta};
                    localStorage.setItem('loginData', JSON.stringify(userData));
                    setUser(getUserData());
                    infoEditRef.current.classList.add('d-none');
                    infoRef.current.classList.remove('d-none');
                    toast('Profile updated successfully', {type: 'success'});
                }
            }).catch(err => {
                setLoading(false);
                toast('Something went wrong', {type: 'error'});
            });
        }else{
            toast('Name can\'t be empty', {type: 'error'});
        }
    }
    return (
        <div className="card bg-a mb-3">
            <div className="card-body">

                <ImageSection />

                <h4 className="text-white text-center mb-3">{user.name}</h4>

                <div className="d-flex justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                        <div ref={infoRef} className="p-2 card bg-a mb-3">
                            <div className="d-flex justify-content-end">
                                <button onClick={()=> {
                                    infoEditRef.current.classList.remove('d-none');
                                    infoRef.current.classList.add('d-none');
                                }} className="btn btn-b btn-sm">
                                    <FontAwesomeIcon icon={faPencilAlt} className="color-a" />
                                </button>
                            </div>
                            <table className="table table-borderless">
                                <tbody>
                                <tr>
                                    <td className="text-white">Gender</td>
                                    <td className="text-white">{user.meta.gender ? firstLetterCapitalized(user.meta.gender) : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Email</td>
                                    <td className="text-white">{user.email}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Phone</td>
                                    <td className="text-white">{user.meta.phone}</td>
                                </tr>
                                <tr>
                                    <td className="text-white">Address</td>
                                    <td className="text-white">{user.meta.address}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div ref={infoEditRef} className="p-3 card bg-a d-none">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input type="text" className="form-control mb-3" id="name" value={info.name} onChange={e=>{
                                    setInfo({...info,name:e.target.value});
                                }} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone</label>
                                <input type="tel" className="form-control" id="phone" value={info.meta.phone} onChange={e=>{
                                    setInfo({...info,meta:{...info.meta,phone:e.target.value}});
                                }} />
                            </div>
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <label htmlFor="gender" className="form-label d-inline">Gender :</label>
                                <div>
                                    <select id="gender" defaultValue={user.meta.gender} className="form-select" onChange={e=>{
                                        setInfo({...info,meta:{...info.meta,gender:e.target.value}});
                                    }}>
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female" >Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address" className="form-label">Address</label>
                                <textarea className="form-control" id="address" rows="3" value={info.meta.address} onChange={e=>{
                                    setInfo({...info,meta:{...info.meta,address:e.target.value}});
                                }}></textarea>
                            </div>
                            <div>
                                <LoadingButton text="Update" onClick={updateInfo} />
                                <button onClick={()=> {
                                    infoEditRef.current.classList.add('d-none');
                                    infoRef.current.classList.remove('d-none');
                                }} className="btn btn-b float-end me-2">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default InfoSection;