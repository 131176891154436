import React, {useContext, useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import LoadingButton from "../../Utilities/LoadingButton";
import axios from "axios";
import {UserContext} from "../../../App";
import {getUserData, isUserLoggedIn} from "../../../Helper/auth";
import {toast} from "react-toastify";

const Login = () => {
    isUserLoggedIn();
    const [user, setUser] = useContext(UserContext);
    const emailRef = useRef();
    const passwordRef = useRef();
    const rememberRef = useRef();
    const handleSubmit = (setLoading,event) => {
        event.preventDefault();
        setLoading(true);
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const remember = rememberRef.current.checked;
        const loginData = {email, password, logged_in: remember, domain: 'sajjadamin.com'};
        axios.post(process.env.REACT_APP_API_BASE_URL+'/auth/login', loginData).then(response => {
            setLoading(false);
            if(response.data.error){
                toast(response.data.error, {type: 'error'});
                setTimeout(() => {
                    if(response.data.error.toLowerCase() === 'email not verified'){
                        localStorage.setItem('unverifiedEmail', email);
                    }
                }, 3000);
            }else{
                localStorage.setItem('loginData', JSON.stringify(response.data));
                setUser(getUserData());
                window.location.replace('/dashboard');
            }
        }).catch(err => {
            setLoading(false);
        });
    }
    return (
        <div className="mt-5 d-flex align-items-center justify-content-center">
            <div className="card bg-a login-card mb-5">
                <div className="card-header text-center pt-3">
                    <h2 className="color-a">Login</h2>
                </div>
                <div className="card-body">
                    <form >
                        <div className="mb-3">
                            <input ref={emailRef} type="email" className="form-control" placeholder="Email"/>
                        </div>
                        <div className="mb-3">
                            <input ref={passwordRef} type="password" className="form-control" placeholder="Password"/>
                        </div>
                        <div className="mb-3 form-check">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <input ref={rememberRef} name="remember" type="checkbox" className="form-check-input" id="loggedin"/>
                                    <label className="form-check-label color-a" htmlFor="loggedin">Remember Me</label>
                                </div>
                                <NavLink to="/auth/forgot-password" className="nav-link color-a">Forgot Password?</NavLink>
                            </div>
                        </div>
                        <LoadingButton text="Login" type="button" onClick={handleSubmit} />
                    </form>
                    <br/><br/>
                    <p>Don't have an account? <NavLink to={'/auth/register'} className="nav-link color-a d-inline">Click here</NavLink> to register</p>
                </div>
            </div>
        </div>
    );
};

export default Login;