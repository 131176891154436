import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd, faChevronLeft, faChevronRight, faClose,
    faEdit,
    faFileAlt, faFileExport,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {UserContext} from "../../../../App";
import moment from "moment";
import Loader from "../../../../Components/Utilities/Loader";
import AddRecordModal from "./AddRecordModal";
import EditRecordModal from "./EditRecordModal";
import DeleteRecordModal from "./DeleteRecordModal";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

const Meter = () => {
    const [user] = useContext(UserContext);
    const {meterId} = useParams();
    const [loading, setLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [search, setSearch] = useState('');
    const [meter, setMeter] = useState({});
    const [records, setRecords] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const initialRecord = {meter_id:meter.id, display_serial:0, date: '', amount: 0, payment_method: 'POS', comment: ''};
    const [editRecord, setEditRecord] = useState(initialRecord);
    const limit = 100;
    const loadMeter = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/utility-bills/electricity/get-meter/${meterId}`, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            setMeter(response.data.data);
        }).catch(error => {
            console.log(error);
        });
    }
    const loadRecords = () => {
        records.length <= 0 && setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/utility-bills/electricity/get-individual-records/${meterId}/${limit}/${page}`, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            setLoading(false);
            setRecords(response.data.data);
            setTotalRecords(response.data.total);
        }).catch(error => {
            console.log(error);
        });
    }
    const handleSearch = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/prp/utility-bills/electricity/individual-search/${meterId}/${limit}/${page}`, {
            search: search
        }, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            setRecords(response.data.data);
            setTotalRecords(response.data.total);
        }).catch(error => {
            console.log(error);
        });
    }
    const handleExport = (all=true) => {
        setIsExporting(true);
        const url = `${process.env.REACT_APP_API_BASE_URL}/prp/utility-bills/electricity/individual-export/${meterId}/${all ? totalRecords : limit}/${page}`
        axios.post(url, {search}, {
            headers: {
                Authorization: 'Bearer ' + user.token
            },
            responseType: 'blob'
        }).then(response => {
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
            setIsExporting(false);
        }).catch(error => {
            setIsExporting(false);
            toast('Something went wrong!', {type: 'error'});
        });
    }
    useEffect(() => {
        loadMeter();
    }, []);
    useEffect(() => {
        loadRecords();
    }, [meterId,page]);
    useEffect(() => {
        setTotalPage(Math.ceil(totalRecords / limit));
    }, [totalRecords]);
    return (
        <div>
            <AddRecordModal
                meterId={meter.id}
                show={showAddDialog}
                setShow={setShowAddDialog}
                loadRecords={loadRecords}
            />
            <EditRecordModal
                meterId={meter.id}
                show={showEditDialog}
                setShow={setShowEditDialog}
                loadRecords={loadRecords}
                setEditRecord={setEditRecord}
                editRecord={editRecord}
            />
            <DeleteRecordModal
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
                recordId={deleteId}
                loadRecords={loadRecords}
            />
            <div className="card bg-a mt-3 mb-3">
                <div className="card-header">
                    <div className="col">
                        <div className="row">
                            <div className="col-4">
                                <button className="btn btn-b me-2 float-start" onClick={()=> {
                                    window.history.back();
                                }}>
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                </button>
                            </div>
                            <div className="col-4">
                                <h5 className="card-title text-center color-a">{meter.title}</h5>
                            </div>
                            <div className="col-4"></div>
                        </div>
                    </div>
                </div>
                <div className="card-body mb-3">
                    <div className="row pt-2 pb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 col-lg-4">
                            <button className="btn btn-a me-2" onClick={()=>setShowAddDialog(true)}>
                                <FontAwesomeIcon icon={faAdd}/> Add Record
                            </button>
                        </div>
                        <div className="col-10 col-md-6 col-lg-5 col-xl-4 pt-3 pt-md-0">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search"
                                       aria-label="Recipient's username" aria-describedby="search-btn"
                                       onChange={(e)=>setSearch(e.target.value)}
                                       onKeyUp={(e)=> {
                                           e.key === 'Enter' && handleSearch();
                                           search === '' && loadRecords();
                                       }}
                                />
                                <button className="btn btn-a" type="button" id="search-btn" onClick={handleSearch}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </button>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 col-lg-3 col-xl-4 pt-3 pt-md-0">
                            <div className="d-flex justify-content-end">
                                <div className="dropdown me-2">
                                    <button className="btn btn-a" type="button" id="exportDropdownBtn"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <FontAwesomeIcon icon={faFileExport} className="me-2"/> Export
                                            {isExporting && <Loader className="ms-2" zoom="1"/>}
                                        </div>
                                    </button>
                                    <ul className="dropdown-menu dropdown-a" aria-labelledby="exportDropdownBtn">
                                        <li><button className="dropdown-item" onClick={()=>handleExport(false)}>Listed</button></li>
                                        <li><button className="dropdown-item" onClick={()=>handleExport()}>All</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ?
                        <div className="p-5 d-flex align-items-center justify-content-center">
                            <Loader zoom="2" />
                        </div> : records.length > 0 ?
                    <div className="table-responsive">
                        <table className="table session-table text-white">
                            <thead>
                            <tr className="bg-b">
                                <th scope="col" className="text-white">#</th>
                                <th className="text-white">Date</th>
                                <th className="text-white">Amount</th>
                                <th className="text-white">Payment Method</th>
                                <th className="text-white">Comment</th>
                                <th className="text-white text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                records.map((record, index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{moment.unix(record.date).format('YYYY-MM-DD h:mm A')}</td>
                                            <td>{record.amount}</td>
                                            <td>{record.payment_method}</td>
                                            <td>{record.comment}</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <button className="btn btn-b me-2" onClick={()=>{
                                                        setShowEditDialog(true);
                                                        setEditRecord({...record, id: record.id, date: new Date(record.date * 1000).toISOString().slice(0, 19)});
                                                    }}>
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </button>
                                                    <button className="btn btn-b" onClick={()=> {
                                                        setDeleteId(record.id);
                                                        setShowDeleteDialog(true)
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                        <nav className="custom-pagination">
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPage(page - 1)}>
                                        <FontAwesomeIcon icon={faChevronLeft}/>
                                    </button>
                                </li>
                                {
                                    Array.from(Array(totalPage).keys()).map((item, index) => {
                                        return (
                                            <li key={index} className={`page-item ${page === item + 1 ? 'active' : ''}`}>
                                                <button className="page-link" onClick={() => setPage(item + 1)}>{item + 1}</button>
                                            </li>
                                        );
                                    })
                                }
                                <li className={`page-item ${page === totalPage ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPage(page + 1)}>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                        :
                        <div className="p-5 d-flex align-items-center justify-content-center color-a">
                            <div className="text-center">
                                <FontAwesomeIcon icon={faFileAlt} size="5x"/>
                                <h3 className="mt-3">No Records Found</h3>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Meter;