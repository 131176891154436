import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../../Components/Utilities/LoadingButton";
import axios from "axios";
import {toast} from "react-toastify";
import {UserContext} from "../../../../App";

const AddRecordModal = ({show, setShow, wifeId, loadRecords}) => {
    const [user] = useContext(UserContext);
    const initialRecord = {
        wife_id: wifeId,
        date: '',
        time: 'Night',
        rating: 2.5,
        comment: ''
    };
    const [newRecord, setNewRecord] = useState(initialRecord);
    useEffect(() => {
        setNewRecord(initialRecord);
    }, []);
    const handleAdd = (setLoading) => {
        if(newRecord.date !== '' && newRecord.time !== '' && newRecord.rating != 0){
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL + '/prp/wife/sex/add-record', newRecord, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).then(response => {
                setLoading(false);
                if(response.data.error){
                    toast(response.data.error, {type: 'error'});
                }else{
                    loadRecords();
                    toast('Record added successfully', {type: 'success'});
                    setShow(false);
                    setNewRecord(initialRecord);
                }
            }).catch(error => {
                toast('Something went wrong', {type: 'error'});
                setLoading(false);
            });
        }else {
            toast('Please fill up all the fields', {type: 'error'});
        }
    }
    const handleChange = (e) => {
        setNewRecord({...newRecord, [e.target.id]: e.target.value});
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> New Record</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="row">
                        <div className="col-6">
                            <div className="mb-3">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input type="date" className="form-control bg-b text-white" id="date" onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-3">
                                <label htmlFor="time" className="form-label">Time</label>
                                <select className="form-select bg-b text-white" id="time" onChange={handleChange}>
                                    <option value="Night">Night</option>
                                    <option value="Midnight">Midnight</option>
                                    <option value="Morning">Morning</option>
                                    <option value="Noon">Noon</option>
                                    <option value="Afternoon">Afternoon</option>
                                    <option value="Evening">Evening</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="rating" className="form-label">Rating</label>
                        <div className="d-flex flex-row-reverse">
                            <span className="color-a">{newRecord.rating}</span>
                            <input type="range" className="form-range me-3" id="rating" step={0.1} min={0} max={5} onChange={handleChange} defaultValue={newRecord.rating}/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control bg-b text-white" id="comment" rows={5} onChange={handleChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Add" onClick={handleAdd}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default AddRecordModal;