import React from 'react';
import Modal from "react-bootstrap/Modal";
import moment from "moment/moment";

const SexStatisticsModal = ({show, setShow, data, date}) => {
    return (
        <Modal className="modal-lg" centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient d-flex flex-column align-items-center justify-content-center">
                    <h2 className="color-a">Statistics</h2>
                    <p className="color-b">{moment(date).format('MMMM YYYY')}</p>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card bg-a">
                                <div className="card-header">
                                    <h5 className="card-title text-center color-a">Rate</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table table-borderless">
                                        <tbody>
                                        <tr className="color-a">
                                            <td>Total of Month</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Max in a Day</td>
                                            <td>:</td>
                                            <td>{Math.max(...data.map(d => d.count))}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Best Rating</td>
                                            <td>:</td>
                                            <td>{Math.max(...data.map(d => Math.max(...d.rating)))}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Worst Rating</td>
                                            <td>:</td>
                                            <td>{Math.min(...data.map(d => Math.min(...d.rating)))}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Average Rating</td>
                                            <td>:</td>
                                            <td>{(data.reduce((a, b) => a + b.rating.reduce((a, b) => a + b, 0), 0) / data.reduce((a, b) => a + b.rating.length, 0)).toFixed(2)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card bg-a">
                                <div className="card-header">
                                    <h5 className="card-title text-center color-a">Day</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table table-borderless">
                                        <tbody>
                                        <tr className="color-a">
                                            <td>Saturday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Saturday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Sunday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Sunday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Monday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Monday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Tuesday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Tuesday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Wednesday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Wednesday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Thursday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Thursday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Friday</td>
                                            <td>:</td>
                                            <td>{data.filter(d => moment(d.date).format('dddd') === 'Friday').reduce((a, b) => a + b.count, 0)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card bg-a">
                                <div className="card-header">
                                    <h5 className="card-title text-center color-a">Time</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table table-borderless">
                                        <tbody>
                                        <tr className="color-a">
                                            <td>Night</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.time.filter(t => t === 'Night').length, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Midnight</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.time.filter(t => t === 'Midnight').length, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Morning</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.time.filter(t => t === 'Morning').length, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Noon</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.time.filter(t => t === 'Noon').length, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Afternoon</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.time.filter(t => t === 'Afternoon').length, 0)}</td>
                                        </tr>
                                        <tr className="color-a">
                                            <td>Evening</td>
                                            <td>:</td>
                                            <td>{data.reduce((a, b) => a + b.time.filter(t => t === 'Evening').length, 0)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>OK</button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default SexStatisticsModal;