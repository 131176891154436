import React, {useContext, useEffect, useRef, useState} from 'react';
import {NavLink, Route, Routes} from "react-router-dom";
import Electricity from "./Electricity/Electricity";
import Water from "./Water/Water";
import Gas from "./Gas/Gas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../Components/Utilities/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import {UserContext} from "../../../App";

const UtilityBills = () => {
    const tabRef = useRef(null);
    useEffect(() => {
        const pathParams = window.location.pathname.split('/');
        const activeTab = pathParams[pathParams.length - 1];
        const buttons = [];
        tabRef.current.querySelectorAll('button').forEach(button => {
            buttons.push(button);
        });
        buttons.forEach(button => {
            if (button.innerText.toLowerCase() === activeTab) {
                button.classList.add('btn-tab-active');
            }
            button.addEventListener('click', () => {
                buttons.forEach(button => {
                    button.classList.remove('btn-tab-active');
                });
                button.classList.add('btn-tab-active');
            });
        });
    }, []);

    const [user] = useContext(UserContext);
    const [isExporting, setIsExporting] = useState(false);
    const handleExport = (all=true) => {
        setIsExporting(true);
        const url = `${process.env.REACT_APP_API_BASE_URL}/prp/utility-bills/export`;
        axios.post(url,null, {
            headers: {
                Authorization: 'Bearer ' + user.token
            },
            responseType: 'blob'
        }).then(response => {
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
            setIsExporting(false);
        }).catch(error => {
            setIsExporting(false);
            toast('Something went wrong!', {type: 'error'});
        });
    }

    return (
        <div className="container">
            <div className="row">
                <nav>
                    <div className="nav nav-tabs mt-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0" role="tablist" ref={tabRef}>
                        <NavLink to="electricity" className="no-text-decoration">
                            <button className="nav-link btn-tab">Electricity</button>
                        </NavLink>
                        <NavLink to="water" className="no-text-decoration">
                            <button className="nav-link btn-tab">Water</button>
                        </NavLink>
                        <NavLink to="gas" className="no-text-decoration">
                            <button className="nav-link btn-tab">Gas</button>
                        </NavLink>


                        <div className="d-flex justify-content-end">
                            <div className="dropdown me-2">
                                <button className="nav-link btn-tab" type="button" id="exportDropdownBtn"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <FontAwesomeIcon icon={faFileExport} className="me-2"/> Export
                                        {isExporting && <Loader className="ms-2" zoom="1"/>}
                                    </div>
                                </button>
                                <ul className="dropdown-menu dropdown-a" aria-labelledby="exportDropdownBtn">
                                    {/*<li>*/}
                                    {/*    <button className="dropdown-item"*/}
                                    {/*            onClick={() => handleExport(false)}>Listed*/}
                                    {/*    </button>*/}
                                    {/*</li>*/}
                                    <li>
                                        <button className="dropdown-item" onClick={() => handleExport()}>All
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </nav>
            </div>
            <div className="row">
                <Routes>
                    <Route path="electricity/*" element={<Electricity/>}/>
                    <Route path="water" element={<Water/>}/>
                    <Route path="gas" element={<Gas/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default UtilityBills;