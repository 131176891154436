import React, {useRef} from 'react';
import {NavLink} from "react-router-dom";
import LoadingButton from "../../Utilities/LoadingButton";
import axios from "axios";

const Register = () => {
    const alertRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmRef = useRef();
    const nameRef = useRef();
    const handleSubmit = (setLoading,event) => {
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const confirm = confirmRef.current.value;
        if(name === ''){
            alertRef.current.classList.remove('d-none');
            alertRef.current.innerText = 'Name is required';
            setTimeout(() => {
                alertRef.current.classList.add('d-none');
            }, 3000);
        }else if(email === ''){
            alertRef.current.classList.remove('d-none');
            alertRef.current.innerText = 'Email is required';
            setTimeout(() => {
                alertRef.current.classList.add('d-none');
            }, 3000);
        }else if(password === ''){
            alertRef.current.classList.remove('d-none');
            alertRef.current.innerText = 'Password is required';
            setTimeout(() => {
                alertRef.current.classList.add('d-none');
            }, 3000);
        }else if(confirm === ''){
            alertRef.current.classList.remove('d-none');
            alertRef.current.innerText = 'Confirm password is required';
            setTimeout(() => {
                alertRef.current.classList.add('d-none');
            }, 3000);
        }
        else if(password !== confirm){
            alertRef.current.classList.remove('d-none');
            alertRef.current.innerText = 'Password and confirm password do not match';
            setTimeout(() => {
                alertRef.current.classList.add('d-none');
            }, 3000);
        }else{
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL+'/auth/signup', {name, email, password}).then(response => {
                setLoading(false);
                if(response.data.error){
                    alertRef.current.innerText = response.data.error;
                    alertRef.current.classList.remove('d-none');
                    setTimeout(() => {
                        alertRef.current.classList.add('d-none');
                    }, 5000);
                }else{
                    localStorage.setItem('unverifiedEmail', email);
                    alertRef.current.classList.remove('alert-danger');
                    alertRef.current.classList.add('alert-success');
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerText = 'Account created successfully. Please check your email to verify your account';
                    setTimeout(() => {
                        alertRef.current.classList.remove('alert-success');
                        alertRef.current.classList.add('alert-danger');
                        alertRef.current.classList.add('d-none');
                        window.location.replace('/auth/verify-account');
                    }, 5000);
                }
            }).catch(err => {
                setLoading(false);
            });
        }
    }
    return (
        <div className="mt-5 d-flex align-items-center justify-content-center">
            <div className="col-md-4 mb-5">
                <div className="card bg-a">
                    <div className="card-header text-center pt-3">
                        <h2 className="color-a">Register</h2>
                    </div>
                    <div className="card-body">
                        <div ref={alertRef} className="alert alert-danger d-none" role="alert">
                        </div>
                        <form >
                            <div className="mb-3">
                                <input ref={nameRef} type="text" className="form-control" placeholder="Enter your full name" />
                            </div>
                            <div className="mb-3">
                                <input ref={emailRef} type="email" className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="mb-3">
                                <input ref={passwordRef} type="password" className="form-control" placeholder="Enter a password" />
                            </div>
                            <div className="mb-3">
                                <input ref={confirmRef} type="password" className="form-control" placeholder="Confirm password" />
                            </div>
                            <LoadingButton text="Register" type="button" onClick={handleSubmit} />
                        </form>
                        <br/><br/>
                        <p>Already have an account? <NavLink to={'/auth/login'} className="nav-link color-a d-inline">Click here</NavLink> to Login</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;