import React, {useContext, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../../Components/Utilities/LoadingButton";
import {toast} from "react-toastify";
import axios from "axios";
import {UserContext} from "../../../../App";

const AddMeterModal = ({show, setShow, totalMeter, loadMeters}) => {
    const [user] = useContext(UserContext);
    const initialData = {
        display_serial: 0,
        title: "",
        customer_number: "",
        meter_serial: "",
        comment: ""
    }
    const [data, setData] = useState(initialData);
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    }
    const handleAdd = (setLoading) => {
        if(data.title !== '' && data.customer_number !== '' && data.meter_serial !== '' && data.display_serial !== 0) {
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL + '/prp/utility-bills/electricity/add-meter', data, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            }).then(res => {
                if (!res.data.error) {
                    toast("Meter added successfully", {type: "success"});
                    setData(initialData);
                    setShow(false);
                    loadMeters();
                }else{
                    toast(res.data.message, {type: "error"});
                }
                setLoading(false);
            }).catch(err => {
                console.log(err);
                setLoading(false);
            });
        }else{
            toast("Please fill all the fields", {type: "error"});
        }
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> New Meter</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Meter Title</label>
                        <input type="text" className="form-control" id="title" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="display_serial" className="form-label">Display Serial</label>
                        <select className="form-select" id="display_serial" onChange={handleChange}>
                            <option value="0">Select Display Serial</option>
                            {
                                Array.from(Array(totalMeter + 1).keys()).map((meter, index) => (
                                    <option key={index} value={index + 1}>{index + 1}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="customer_number" className="form-label">Customer No</label>
                        <input type="text" className="form-control" id="customer_number" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="meter_serial" className="form-label">Meter Serial No</label>
                        <input type="text" className="form-control" id="meter_serial" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Add" onClick={handleAdd}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default AddMeterModal;