import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {UserContext} from "../../../../App";
import moment from "moment";
import LoadingButton from "../../../Utilities/LoadingButton";
import SessionClockUserDefined from "../../../Utilities/SessionClockUserDefined";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";

const SessionSection = () => {
    const [user, setUser] = useContext(UserContext);
    const [tokens, setTokens] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [sessionId, setSessionId] = useState(0);
    const getTokensFromServer = () => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/profile/get-tokens', {
            headers: {
                Authorization: 'Bearer ' + user.token,
            }
        }).then(response => {
            if(!response.data.error) {
                setTokens(response.data.tokens);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    useEffect(() => {
        getTokensFromServer();
    }, []);
    const handleLogout = (setLoading) => {
        setLoading(true);
        axios.delete(process.env.REACT_APP_API_BASE_URL + '/profile/remove-token/' + sessionId, {
            headers: {
                Authorization: 'Bearer ' + user.token,
            }
        }).then(response => {
            if (!response.data.error) {
                getTokensFromServer();
                setShowDialog(false);
                setLoading(false)
                toast('Session logout successful', {type: 'success'});
            }else{
                setLoading(false)
                toast('Session logout failed', {type: 'error'});
            }
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    return (
        <div>
            <Modal centered show={showDialog} backdrop="static" onHide={()=>setShowDialog(false)}>
                <div className="logout-modal">
                    <Modal.Header className="bg-a bg-gradient">
                        <Modal.Title className="text-warning">Logout Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-a">Are you sure you want to logout from this session?</Modal.Body>
                    <Modal.Footer className="bg-a">
                        <LoadingButton text="Yes" onClick={handleLogout}>Clos</LoadingButton>
                        <button className="btn btn-b" onClick={()=>setShowDialog(false)}>No</button>
                    </Modal.Footer>
                </div>
            </Modal>
            <div className="card bg-a mb-3">
                <div className="card-body mb-3">
                    <div className="table-responsive">
                        <table className="table session-table text-white">
                            <thead>
                            <tr className="bg-b">
                                <th scope="col" className="text-white">#</th>
                                <th className="text-white">Date</th>
                                <th className="text-white">IP</th>
                                <th className="text-white">Device</th>
                                <th className="text-white">Location</th>
                                <th className="text-white">End</th>
                                <th className="text-white">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tokens.map((token, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{moment.unix(token.issued_at).format('MMMM D YYYY, h:mm:ss A')}</td>
                                        <td>{JSON.parse(token.location).ip}</td>
                                        <td>
                                            {JSON.parse(token.platform).platform}, {JSON.parse(token.platform).browser}
                                        </td>
                                        <td>
                                            {JSON.parse(token.location).cityName}, {JSON.parse(token.location).countryName}
                                        </td>
                                        <td>
                                            <SessionClockUserDefined endTime={token.expires_at} />
                                        </td>
                                        <td>
                                            {JSON.parse(atob(user.token)).expires != token.expires_at ?
                                                <div className="d-flex">
                                                    <LoadingButton text="Logout" onClick={()=>{
                                                        setShowDialog(true);
                                                        setSessionId(token.id);
                                                    }
                                                    } />
                                                </div>
                                                : <button className="btn btn-b" disabled>Logout</button>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionSection;