import React, {useContext, useEffect} from 'react';
import {getUserData} from "../../Helper/auth";
import {UserContext} from "../../App";

const SessionClock = () => {
    const [user,setUser] = useContext(UserContext);
    const [seconds, setSeconds] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [hours, setHours] = React.useState(0);
    const [days, setDays] = React.useState(0);
    const [shouldLogout, setShouldLogout] = React.useState(false);
    let interval = setInterval(() => {
        const time = JSON.parse(atob(getUserData().token)).expires;
        const now = Math.floor(Date.now() / 1000);
        const diff = time - now;
        const days = Math.floor(diff / 86400);
        const hours = Math.floor((diff - (days * 86400)) / 3600);
        const minutes = Math.floor((diff - (days * 86400) - (hours * 3600)) / 60);
        const seconds = diff - (days * 86400) - (hours * 3600) - (minutes * 60);
        setSeconds(seconds);
        setMinutes(minutes);
        setHours(hours);
        setDays(days);
        if(diff <= 0){
            setShouldLogout(true);
        }
    }, 1000);
    useEffect(() => {
        if(shouldLogout){
            localStorage.removeItem('loginData');
            setUser({});
            window.location.replace('/auth/login');
        }
        return () => {
            clearInterval(interval);
        }
    }, [shouldLogout]);

    return (
        <div className="d-inline">
            {hours >= 0 ? `${days > 9 ? days : '0'+days}:${hours > 9 ? hours : '0'+hours}:${minutes > 9 ? minutes : '0'+minutes}:${seconds > 9 ? seconds : '0'+seconds}` : '00:00:00:00'}
        </div>
    );
};

export default SessionClock;