import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faChevronLeft,
    faChevronRight,
    faEdit, faFileAlt,
    faFileExport, faInfo, faInfoCircle,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../Components/Utilities/Loader";
import moment from "moment/moment";
import {UserContext} from "../../../App";
import {useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import AddRecordModal from "./AddRecordModal";
import EditRecordModal from "./EditRecordModal";
import DeleteRecordModal from "./DeleteRecordModal";
import DetailsModal from "./DetailsModal";
import {isNullOrUndef} from "chart.js/helpers";

const Expense = () => {
    const [user] = useContext(UserContext);
    const {expenseId} = useParams();
    const [loading, setLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [search, setSearch] = useState('');
    const [expense, setExpense] = useState({});
    const [records, setRecords] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalExpense, setTotalExpense] = useState(0);
    const [addRecordModalShow, setAddRecordModalShow] = useState(false);
    const [editRecordModalShow, setEditRecordModalShow] = useState(false);
    const [deleteRecordModalShow, setDeleteRecordModalShow] = useState(false);
    const [editRecord, setEditRecord] = useState({});
    const [deleteId, setDeleteId] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const limit = 100;

    const loadExpense = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/expense/get/${expenseId}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }).then(res => {
            if (!res.data.error) {
                setExpense(res.data.data);
                setLoading(false);
            }else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            toast('Something went wrong!\nData could not be loaded from the server.', {type: 'error'});
        });
    }
    const loadRecords = () => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/expense/records/get/${expenseId}/${limit}/${page}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        }).then(res => {
            if (!res.data.error) {
                setRecords(res.data.data);
                setTotalRecords(res.data.total);
                setTotalExpense(res.data.totalExpense);
                setLoading(false);
            }else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
            toast('Something went wrong!\nData could not be loaded from the server.', {type: 'error'});
        });
    }
    const handleSearch = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/prp/expense/records/search/${expenseId}/${limit}/${page}`, {
            search: search
        }, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(res => {
            setRecords(res.data.data);
            setTotalRecords(res.data.total);
        }).catch(error => {
            console.log(error);
        });
    }

    const handleExport = () => {
        setIsExporting(true);
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/prp/expense/export`, {id: expenseId},{
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(res => {
            if (!res.data.error) {
                let text = '';
                const data = res.data.data;
                text += data.expense.title + '\n\n';
                JSON.parse(data.expense.deposits).forEach((deposit, index) => {
                    text += moment(deposit.date).format('DD MMM YYYY') + ' - ' + deposit.amount + ' : ';
                    text += deposit.description + '\n';
                });
                text += '----------------------------\n';
                text += !isNullOrUndef(data.expense.description) ? data.expense.description + '\n\n' : '';
                text += 'Budget      : '+data.expense.budget + '\n'
                text += 'Total Spend : '+data.totalExpense + '\n';
                text += 'Remaining   : '+ (Number.parseInt(data.expense.budget) - Number.parseInt(data.totalExpense));
                text += '\n----------------------------\n\n';
                data.records.forEach((record, index) => {
                    text += '\t'+moment.unix(record.date).format('DD MMM YYYY')+'\n\t----------------------------\n';
                    JSON.parse(record.expenses).forEach((expense, index) => {
                       text += expense.item + ' - ' + expense.price + '\n';
                    });
                    text += '----------------------------\nTotal : '+record.total+'\n\n';
                });
                // download text file
                const element = document.createElement("a");
                const file = new Blob([text], {type: 'text/plain'});
                element.href = URL.createObjectURL(file);
                element.download = data.expense.title + '.txt';
                document.body.appendChild(element);
                element.click();
            } else {
                toast(res.data.message, {type: 'error'});
            }
            setIsExporting(false)
        }).catch(error => {
            console.log(error);
            setIsExporting(false)
        });
    }

    useEffect(() => {
        loadExpense();
    }, []);
    useEffect(() => {
        loadRecords();
    }, [expenseId,page]);
    useEffect(() => {
        setTotalPage(Math.ceil(totalRecords / limit));
    }, [totalRecords]);
    return (
        <div className="container">
            <AddRecordModal
                show={addRecordModalShow}
                setShow={setAddRecordModalShow}
                expenseId={expenseId}
                loadRecords={loadRecords}
            />
            <EditRecordModal
                show={editRecordModalShow}
                setShow={setEditRecordModalShow}
                editValue={editRecord}
                setEditValue={setEditRecord}
                loadRecords={loadRecords}
            />
            <DeleteRecordModal
                show={deleteRecordModalShow}
                setShow={setDeleteRecordModalShow}
                deleteId={deleteId}
                loadRecords={loadRecords}
            />
            <DetailsModal
                show={showDetails}
                setShow={setShowDetails}
                totalSpend={totalExpense}
                expense={expense}
            />
            <div className="card bg-a mt-sm-3 mt-md-3 mt-lg-0 mt-xl-0 mb-3">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-4">
                            <button className="btn btn-b me-2 float-start" onClick={()=> {
                                window.history.back();
                            }}>
                                <FontAwesomeIcon icon={faChevronLeft}/>
                            </button>
                        </div>
                        <div className="col-4">
                            <h5 className="card-title text-center color-a">{expense.title}</h5>
                        </div>
                        <div className="col-4">
                            <div className="d-flex justify-content-end">
                                <div className="dropdown me-2">
                                    <button className="btn btn-b" onClick={handleExport}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <FontAwesomeIcon icon={faFileExport} className="me-2"/> Export
                                            {isExporting && <Loader className="ms-2" zoom="1"/>}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body mb-3">
                    <div className="row pt-2 pb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 col-lg-4">
                            <button className="btn btn-a me-2" onClick={()=>setAddRecordModalShow(true)}>
                                <FontAwesomeIcon icon={faAdd}/> Add Record
                            </button>
                        </div>
                        <div className="col-10 col-md-6 col-lg-5 col-xl-4 pt-3 pt-md-0">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search"
                                       aria-label="Recipient's username" aria-describedby="search-btn"
                                       onChange={(e)=>setSearch(e.target.value)}
                                       onKeyUp={(e)=> {
                                           e.key === 'Enter' && handleSearch();
                                           search === '' && loadRecords();
                                       }}
                                />
                                <button className="btn btn-a" type="button" id="search-btn" onClick={handleSearch}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </button>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 col-lg-3 col-xl-4 pt-3 pt-md-0">
                            <button className="btn btn-a me-2 float-end" onClick={()=> {
                                setShowDetails(true);
                            }}>
                                <FontAwesomeIcon icon={faInfoCircle} className="me-2"/>Show Details
                            </button>
                        </div>
                    </div>
                    {loading ?
                        <div className="p-5 d-flex align-items-center justify-content-center">
                            <Loader zoom="2" />
                        </div> : records.length > 0 ?
                            <div className="table-responsive">
                                <table className="table session-table text-white">
                                    <thead>
                                    <tr className="bg-b text-center">
                                        <th scope="col" className="text-white">#</th>
                                        <th className="text-white">Date</th>
                                        <th className="text-white">Items</th>
                                        <th className="text-white">Total</th>
                                        <th className="text-white">Comment</th>
                                        <th className="text-white text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        records.map((record, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td className="text-center">{moment.unix(record.date).format('DD MMM YYYY')}</td>
                                                    <td>
                                                        {
                                                            JSON.parse(record.expenses).map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div key={index} className="row">
                                                                            <div className="col-8">
                                                                                <p className="mb-0">{item.item}</p>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <p className="mb-0">{item.price}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </td>
                                                    <td className="text-center">{record.total}</td>
                                                    <td style={{maxWidth:'200px'}}>{record.comment}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <button className="btn btn-b me-2" onClick={()=>{
                                                                setEditRecordModalShow(true);
                                                                setEditRecord({
                                                                    ...record,
                                                                    id: record.id,
                                                                    date: moment.unix(record.date).format('YYYY-MM-DD'),
                                                                    expenses: JSON.parse(record.expenses),
                                                                });
                                                            }}>
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </button>
                                                            <button className="btn btn-b" onClick={()=> {
                                                                setDeleteId(record.id);
                                                                setDeleteRecordModalShow(true);
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                                <nav className="custom-pagination">
                                    <ul className="pagination justify-content-center">
                                        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => setPage(page - 1)}>
                                                <FontAwesomeIcon icon={faChevronLeft}/>
                                            </button>
                                        </li>
                                        {
                                            Array.from(Array(totalPage).keys()).map((item, index) => {
                                                return (
                                                    <li key={index} className={`page-item ${page === item + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => setPage(item + 1)}>{item + 1}</button>
                                                    </li>
                                                );
                                            })
                                        }
                                        <li className={`page-item ${page === totalPage ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => setPage(page + 1)}>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            :
                            <div className="p-5 d-flex align-items-center justify-content-center color-a">
                                <div className="text-center">
                                    <FontAwesomeIcon icon={faFileAlt} size="5x"/>
                                    <h3 className="mt-3">No Records Found</h3>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Expense;