import React, {useContext, useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faClose, faFileMedical} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import axios from "axios";
import {UserContext} from "../../../App";
import LoadingButton from "../../../Components/Utilities/LoadingButton";
import UserAvatar from "../../../Assets/Images/user-avatar.png";

const AddWifeModal = ({show, setShow, loadWives}) => {
    const [user] = useContext(UserContext);
    const [image, setImage] = useState(null);
    const imageRef = useRef();
    const editFileRef = useRef();
    const initialData = {
        image: image,
        name: '',
        dob: '',
        marriage_date: '',
        is_alive: '1',
        death_date: '',
        is_divorced: '0',
        divorce_date: '',
        comment: ''
    }
    const [data, setData] = useState(initialData);
    useEffect(() => {
        if(image){
            const reader = new FileReader();
            reader.onload = () => {
                imageRef.current.src = reader.result;
            }
            reader.readAsDataURL(image);
        }
        setData({...data, image: image})
    },[image]);
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    }
    const handleAdd = (setLoading) => {
        if(data.name !== '' && data.dob !== '' && data.marriage_date !== '' && data.is_alive !== '' && data.is_divorced !== ''){
            setLoading(true);
            let formData = new FormData();
            formData.append('image', data.image);
            formData.append('name', data.name);
            formData.append('dob', data.dob);
            formData.append('marriage_date', data.marriage_date);
            formData.append('is_alive', data.is_alive);
            formData.append('death_date', data.death_date);
            formData.append('is_divorced', data.is_divorced);
            formData.append('divorce_date', data.divorce_date);
            formData.append('comment', data.comment);
            axios.post(process.env.REACT_APP_API_BASE_URL + '/prp/wife/add-wife', formData, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                },
            }).then(response => {
                setLoading(false);
                if (response.data.error) {
                    toast(response.data.error, {type: 'error'});
                } else {
                    toast('Wife added successfully', {type: 'success'});
                    setShow(false);
                    setData(initialData);
                    loadWives();
                }
            }).catch(error => {
                setLoading(false);
                toast('Something went wrong', {type: 'error'});
            });
        }else{
            toast('Please fill all the fields', {type: 'error'});
        }
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> New Wife</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="d-flex mb-3 align-items-center justify-content-center pt-3">
                        <img ref={imageRef} src={UserAvatar} alt="profile" className="img-thumbnail user-image-profile" />
                        <button
                            className="btn btn-a btn-sm user-image-profile-upload-btn"
                            onClick={()=> {
                                if(image){
                                    setImage(null);
                                    imageRef.current.src = UserAvatar;
                                }else{
                                    editFileRef.current.click()
                                }
                            }}
                        >
                            {
                                image ? <FontAwesomeIcon icon={faClose} className="text-white" /> : <FontAwesomeIcon icon={faCamera} className="text-white" />
                            }
                        </button>
                        <input type="file" ref={editFileRef} className="d-none" onChange={e=> {
                            setImage(e.target.files[0])
                        }}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" className="form-control" id="name" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="dob" className="form-label">Date of Birth</label>
                        <input type="date" className="form-control" id="dob" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="marriage_date" className="form-label">Marriage Date</label>
                        <input type="date" className="form-control" id="marriage_date" onChange={handleChange}/>
                    </div>
                    <div className="row">
                        <div className={data.is_alive === '1' ? 'col-md-12' : 'col-md-6'}>
                            <div className="mb-3">
                                <label htmlFor="is_alive" className="form-label">Alive</label>
                                <select className="form-select" id="is_alive" onChange={handleChange}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                        </div>
                        {
                            data.is_alive === '0' &&
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="death_date" className="form-label">Death Date</label>
                                    <input type="date" className="form-control" id="death_date" onChange={handleChange}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className={data.is_divorced === '0' ? 'col-md-12' : 'col-md-6'}>
                            <div className="mb-3">
                                <label htmlFor="is_divorced" className="form-label">Divorced</label>
                                <select className="form-select" id="is_divorced" onChange={handleChange}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>
                        {
                            data.is_divorced === '1' &&
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="divorce_date" className="form-label">Divorce Date</label>
                                    <input type="date" className="form-control" id="divorce_date" onChange={handleChange}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Add" onClick={handleAdd}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default AddWifeModal;