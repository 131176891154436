import React, {useEffect, useRef} from 'react';
import InfoSection from "./InfoSection";
import PasswordSection from "./PasswordSection";
import SessionSection from "./SessionSection";

const Profile = () => {
    const tabRef = useRef(null);
    useEffect(() => {
        const buttons = [];
        tabRef.current.querySelectorAll('button').forEach(button => {
            buttons.push(button);
        });
        buttons.forEach(button => {
            button.addEventListener('click', () => {
                buttons.forEach(button => {
                    button.classList.remove('btn-tab-active');
                });
                button.classList.add('btn-tab-active');
            });
        });
    }, []);
    return (
        <div className="container">
            <div className="row">
                <nav>
                    <div className="nav nav-tabs mt-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0" role="tablist" ref={tabRef}>
                        <button className="nav-link active btn-tab-active btn-tab" id="nav-info-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-info" type="button" role="tab" aria-controls="nav-info"
                                aria-selected="true">Information
                        </button>
                        <button className="nav-link btn-tab" id="nav-password-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-password" type="button" role="tab" aria-controls="nav-password"
                                aria-selected="false">Password
                        </button>
                        <button className="nav-link btn-tab" id="nav-session-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-session" type="button" role="tab" aria-controls="nav-session"
                                aria-selected="false">Session
                        </button>
                    </div>
                </nav>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">
                        <div className="row">
                            <div className="col-lg-12">
                                <InfoSection />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
                        <div className="row">
                            <div className="col-lg-12">
                                <PasswordSection />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-session" role="tabpanel" aria-labelledby="nav-session-tab">
                        <div className="row">
                            <div className="col-lg-12">
                                <SessionSection />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;