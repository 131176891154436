import React, {useContext, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartColumn,
    faChevronLeft, faRedo,
} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../../../../App";
import {useParams} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {Chart} from "chart.js/auto";
import SexStatisticsModal from "./SexStatisticsModal";

const Statistics = () => {
    const [user] = useContext(UserContext);
    const {wifeId} = useParams()
    const [records, setRecords] = useState([]);
    const [chart1Data, setChart1Data] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const monthlyGraphCanvasRef = React.useRef(null);
    const allTimeGraphCanvasRef = React.useRef(null);
    const tabRef = useRef(null);
    const [chart1, setChart1] = useState(null);
    const [chart2, setChart2] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState((function (){
        let month = new Date().getMonth() + 1;
        if(month < 10){
            month = '0' + month;
        }
        return month;
    })());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedDateTimeFrom, setSelectedDateTimeFrom] = useState('2021-10-01');
    const [selectedDateTimeTo, setSelectedDateTimeTo] = useState(moment().format('YYYY-MM-DD'));
    const chartPlugins = [
        {
            'id': 'customCanvasBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const {ctx} = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#99ffff';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            }
        }
    ]

    useEffect(() => {
        const buttons = [];
        tabRef.current.querySelectorAll('button').forEach(button => {
            buttons.push(button);
        });
        buttons.forEach(button => {
            button.addEventListener('click', () => {
                buttons.forEach(button => {
                    button.classList.remove('btn-tab-active');
                });
                button.classList.add('btn-tab-active');
            });
        });
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/prp/wife/sex/get-all/${wifeId}`, {
            headers: {
                Authorization: 'Bearer ' + user.token
            }
        }).then(response => {
            if(!response.data.error){
                setRecords(response.data.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        const searchDate = selectedYear + '-' + selectedMonth;
        const filter = records.filter(record => {
            return record.datetime.includes(searchDate);
        });
        const data = [];
        let totalDayOfMonth = moment(searchDate, 'YYYY-MM').daysInMonth();
        for(let i = 1; i <= totalDayOfMonth; i++){
            let dayOfMonth = i < 10 ? '0' + i : i;
            let obj = {
                day: i,
                count: 0,
                rating: [],
                time: [],
                date: searchDate + '-' + dayOfMonth
            }
            if(filter.find(record => record.datetime.includes(dayOfMonth))){
                obj.count = filter.filter(record => record.datetime.split('-')[2].includes(dayOfMonth)).length;
                obj.rating = filter.filter(record => record.datetime.split('-')[2].includes(dayOfMonth)).map(record => record.rating);
                obj.time = filter.filter(record => record.datetime.split('-')[2].includes(dayOfMonth)).map(record => record.time);
            }
            data.push(obj);
        }
        setChart1Data(data)
        chart1?.destroy();
        const ch1 = new Chart(monthlyGraphCanvasRef.current, {
            type: 'bar',
            data: {
                labels: data.map(d => d.day),
                datasets: [
                    {
                        label: 'Total in Day',
                        data: data.map(d => d.count),
                        backgroundColor: '#a94a4a',
                    },
                    {
                        label: 'Rating',
                        data: data.map(d => {
                            let sum = 0;
                            d.rating.forEach(r => {
                                sum += r;
                            });
                            return sum / d.rating.length;
                        }),
                        backgroundColor: '#4a4aa9',
                        hidden: true
                    }
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1,
                        }
                    }
                },
                plugins: {
                    customCanvasBackgroundColor: {
                        color: '#30003c',
                    }
                }
            },
            plugins: chartPlugins
        });
        setChart1(ch1);

    }, [records, selectedMonth, selectedYear]);

    useEffect(() => {
        const months = [];
        let start = moment(selectedDateTimeFrom);
        let end = moment(selectedDateTimeTo);
        while(start <= end){
            months.push(start.format('YYYY-MM'));
            start.add(1, 'month');
        }
        let data = [];
        months.forEach(month => {
            let filter = records.filter(record => {
                return record.datetime.includes(month);
            });
            let obj = {
                month: month+'-01',
                count: 0,
                avgRating: 0,
            }
            if(filter.length > 0){
                obj.count = filter.length;
                let sum = 0;
                filter.forEach(record => {
                    sum += record.rating;
                });
                obj.avgRating = (sum / filter.length).toFixed(2);
            }
            data.push(obj);
        });
        chart2?.destroy();
        const ch2 = new Chart(allTimeGraphCanvasRef.current, {
            type: 'line',
            data: {
                labels: data.map(d => moment(d.month).format('MMM YYYY')),
                datasets: [
                    {
                        label: 'Total in Month',
                        data: data.map(d => d.count),
                        backgroundColor: '#a94a4a',
                        borderColor: '#a94a4a',
                        tension: 0.2,
                        pointRadius: 5,
                        pointBackgroundColor: '#e38d8d',
                    },
                    {
                        label: 'Average Rating',
                        data: data.map(d => d.avgRating),
                        backgroundColor: '#4a4aa9',
                        borderColor: '#4a4aa9',
                        tension: 0.2,
                        pointRadius: 5,
                        pointBackgroundColor: '#8d8de3',
                        hidden: true
                    }
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1,
                        }
                    }
                },
                plugins: {
                    customCanvasBackgroundColor: {
                        color: '#30003c',
                    }
                }
            },
            plugins: chartPlugins
        });
        setChart2(ch2);
    }, [records, selectedDateTimeFrom, selectedDateTimeTo]);

    return (
        <div className="container">
            <SexStatisticsModal
                show={showDetails}
                setShow={setShowDetails}
                data={chart1Data}
                date={`${selectedYear}-${selectedMonth}-01`}
            />
            <nav className="mt-3">
                <div className="nav nav-tabs justify-content-center mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0" role="tablist" ref={tabRef}>
                    <button className="nav-link active btn-tab-active btn-tab" id="daily-graph-tab" data-bs-toggle="tab"
                            data-bs-target="#daily-graph" type="button" role="tab" aria-controls="daily-graph-tab"
                            aria-selected="true">Daily Graph
                    </button>
                    <button className="nav-link btn-tab" id="monthly-graph-tab" data-bs-toggle="tab"
                            data-bs-target="#monthly-graph" type="button" role="tab" aria-controls="monthly-graph-tab"
                            aria-selected="false">Monthly Graph
                    </button>
                </div>
            </nav>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="daily-graph" role="tabpanel" aria-labelledby="daily-graph-tab">
                    <div className="card bg-a mb-3">
                        <div className="card-header">
                            <div className="col">
                                <div className="row">
                                    <div className="col-4">
                                        <button className="btn btn-b me-2 float-start" onClick={()=> {
                                            window.history.back();
                                        }}>
                                            <FontAwesomeIcon icon={faChevronLeft}/>
                                        </button>
                                    </div>
                                    <div className="col-4">
                                        <h5 className="card-title text-center color-a"></h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <button className="btn btn-b" type="button" id="predictions-btn"
                                                    onClick={() => setShowDetails(true)}>
                                                <FontAwesomeIcon icon={faChartColumn} className="me-2"/> Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mb-3">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="col-6 me-2">
                                            <select className="form-select ms-2" aria-label="Default select example" onChange={e => {setSelectedMonth(e.target.value)}} defaultValue={selectedMonth}>
                                                <option value="01">January</option>
                                                <option value="02">February</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>
                                        </div>
                                        <div className="col-6 ms-2">
                                            <select className="form-select" aria-label="Default select example" onChange={e => {setSelectedYear(e.target.value)}} defaultValue={selectedYear}>
                                                {
                                                    (function (){
                                                        let years = [];
                                                        for(let i = 2021; i <= new Date().getFullYear(); i++){
                                                            years.push(i);
                                                        }
                                                        return years.map(year => {
                                                            return <option key={year} value={year}>{year}</option>
                                                        })
                                                    })()
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-3 d-flex justify-content-end">
                                    <p className="color-a">Total in this month: {chart1Data.reduce((a, b) => a + b.count, 0)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <canvas ref={monthlyGraphCanvasRef}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="monthly-graph" role="tabpanel" aria-labelledby="monthly-graph-tab">
                    <div className="card bg-a mb-3">
                        <div className="card-header">
                            <div className="row mt-2 mb-2 d-flex justify-content-start">
                                <div className="col-md-2">
                                    <button className="btn btn-b me-2 float-start" onClick={()=> {
                                        window.history.back();
                                    }}>
                                        <FontAwesomeIcon icon={faChevronLeft}/>
                                    </button>
                                </div>
                                <div className="col-md-8 d-flex align-items-start justify-content-center">
                                    <div className="col-md-4">
                                        <input type="date" className="form-control" onChange={e => {setSelectedDateTimeFrom(e.target.value)}} defaultValue={selectedDateTimeFrom}/>
                                    </div>
                                    <h5 className="col-md-2 text-center">To</h5>
                                    <div className="col-md-4">
                                        <input type="date" className="form-control" onChange={e => {setSelectedDateTimeTo(e.target.value)}} defaultValue={selectedDateTimeTo}/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="btn btn-b" type="button" id="predictions-btn"
                                                onClick={() => {
                                                    setSelectedDateTimeFrom('2021-10-01');
                                                    setSelectedDateTimeTo(moment().format('YYYY-MM-DD'));
                                                }}>
                                            <FontAwesomeIcon icon={faRedo} className="me-2"/> Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mb-3">

                            <div className="row">
                                <canvas ref={allTimeGraphCanvasRef}></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;