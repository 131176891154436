import React, {useContext, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../../Components/Utilities/LoadingButton";
import axios from "axios";
import {UserContext} from "../../../../App";
import {toast} from "react-toastify";

const DeleteRecordModal = ({show, setShow, recordId, loadRecords}) => {
    const [user] = useContext(UserContext);
    const [confirm, setConfirm] = useState('');
    const handleDelete = (setLoading) => {
        if (confirm === 'Confirm') {
            setLoading(true);
            axios.delete(process.env.REACT_APP_API_BASE_URL + '/prp/utility-bills/water/delete-record/' + recordId, {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            }).then(response => {
                if(response.data.error){
                    toast(response.data.error, {type: 'error'});
                }else{
                    setShow(false);
                    loadRecords();
                    toast('Record deleted successfully', {type: 'success'});
                }
                setLoading(false);
            }).catch(error => {
                setShow(false);
                setLoading(false);
            });
        }
    }
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-danger"><FontAwesomeIcon icon={faTrash}/> Delete Record</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="confirm" className="form-label">Type "Confirm" to delete</label>
                        <input type="text" className="form-control bg-b text-white" id="confirm" onChange={e => setConfirm(e.target.value)}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Delete" onClick={handleDelete}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default DeleteRecordModal;