import React, {useContext, useEffect, useState} from 'react';
import ReactHlsPlayer from "react-hls-player";
import {useParams} from "react-router-dom";
import axios from "axios";
import {isUserAdmin, isUserLoggedIn} from "../../../Helper/auth";
import {UserContext} from "../../../App";

const LivePlayer = () => {
    const params = useParams();
    const [user] = useContext(UserContext);
    const [data, setData] = useState({});
    const [url, setUrl] = useState('');
    useEffect(()=>{
        let apiUrl = process.env.REACT_APP_API_BASE_URL+'/live';
        let config = {};
        if(isUserLoggedIn()){
            if(isUserAdmin()){
                apiUrl += '/moderation/'+params.slug;
            }else{
                apiUrl += '/protected/'+params.slug;
            }
            config = {
                headers: {
                    'Authorization': 'Bearer '+user.token
                }
            }
        }else{
            apiUrl += '/'+params.slug;
        }
        axios.get(apiUrl,config).then(r=>{
            setData(r.data.live);
            if(!r.data.error){

            }
        });
    },[]);
    useEffect(()=>{
        if(data.link){
            setUrl(data.link+'/'+data.key+'.m3u8');
        }else{
            setUrl(`https://live.sajjadamin.com/hls/${data.key}.m3u8`);
        }
    },[data]);
    return (
        <div className="container">
            <h1 className="color-a">{data.title}</h1>
            <hr/>
            <ReactHlsPlayer
                src={url}
                autoPlay={true}
                controls={true}
                muted={true}
                width="100%"/>
            <p className="mt-3">{data.description}</p>
        </div>
    );
};

export default LivePlayer;