import React, {useRef} from 'react';
import {NavLink} from "react-router-dom";
import LoadingButton from "../../Utilities/LoadingButton";
import LoadingLink from "../../Utilities/LoadingLink";
import axios from "axios";

const VerifyAccount = () => {
    const resendRef = useRef();
    const verifyRef = useRef();
    const resendOtpRef = useRef();
    const haveOtpRef = useRef();
    const alertRef = useRef();
    const emailRef = useRef();
    const otpRef = useRef();
    const resendOtpClick = (setLoading,event) => {
        emailRef.current.value = localStorage.getItem('unverifiedEmail') || '';
        resendRef.current.classList.remove('d-none');
        verifyRef.current.classList.add('d-none');
        resendOtpRef.current.classList.add('d-none');
        haveOtpRef.current.classList.remove('d-none');
    }
    const haveOtpClick = (setLoading,event) => {
        otpRef.current.value = '';
        resendRef.current.classList.add('d-none');
        verifyRef.current.classList.remove('d-none');
        resendOtpRef.current.classList.remove('d-none');
        haveOtpRef.current.classList.add('d-none');
    }
    const verifyOtp = (setLoading,event) => {
        const otp = otpRef.current.value;
        const email = localStorage.getItem('unverifiedEmail');
        if(otp !== ''){
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL+'/auth/verify-email', {email,otp}).then(res => {
                setLoading(false);
                if(res.data.error){
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerHTML = res.data.error;
                    setTimeout(() => {
                        alertRef.current.classList.add('d-none');
                    }, 3000);
                }else{
                    localStorage.removeItem('unverifiedEmail');
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerHTML = 'Account verified successfully, please login';
                    alertRef.current.classList.remove('alert-danger');
                    alertRef.current.classList.add('alert-success');
                    setTimeout(() => {
                        alertRef.current.classList.remove('alert-success');
                        alertRef.current.classList.add('alert-danger');
                        alertRef.current.classList.add('d-none');
                        window.location.replace('/auth/login');
                    }, 3000);
                }
            }).catch(err => {
                setLoading(false);
            });
        }
    }
    const resendOtp = (setLoading,event) => {
        const email = emailRef.current.value;
        if(email === ''){
            alertRef.current.classList.remove('d-none');
            alertRef.current.innerHTML = 'Email is required';
            setTimeout(() => {
                alertRef.current.classList.add('d-none');
            }, 3000);
        }else{
            setLoading(true);
            axios.post(process.env.REACT_APP_API_BASE_URL+'/auth/resend-otp', {email: email}).then(res => {
                setLoading(false);
                if(res.data.error){
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerHTML = res.data.error;
                    setTimeout(() => {
                        alertRef.current.classList.add('d-none');
                    }, 10000);
                }else{
                    alertRef.current.classList.remove('d-none');
                    alertRef.current.innerHTML = 'OTP sent to your email, please check and verify your account';
                    alertRef.current.classList.remove('alert-danger');
                    alertRef.current.classList.add('alert-success');
                    setTimeout(() => {
                        alertRef.current.classList.remove('alert-success');
                        alertRef.current.classList.add('alert-danger');
                        alertRef.current.classList.add('d-none');
                    }, 3000);
                }
            }).catch(err => {
               setLoading(false);
            });
        }
    }
    return (
        <div className="mt-5 d-flex align-items-center justify-content-center">
            <div className="col-md-4 mb-5">
                <div className="card bg-a">
                    <div className="card-header text-center pt-3">
                        <h2 className="color-a">Verify Your Account</h2>
                    </div>
                    <div className="card-body">
                        <div ref={alertRef} className="alert alert-danger d-none" role="alert">
                        </div>
                        <div ref={resendRef} className="d-none">
                            <div className="mb-3">
                                <input ref={emailRef} type="email" className="form-control" placeholder="Email"/>
                            </div>
                            <LoadingButton text="Resend OTP" type="button" onClick={resendOtp} />
                        </div>
                        <div ref={verifyRef}>
                            <div className="mb-3">
                                <input ref={otpRef} type="text" className="form-control" placeholder="Enter OTP"/>
                            </div>
                            <LoadingButton text="Verify" type="button" onClick={verifyOtp} />
                        </div>
                        <br/><br/>
                        <p ref={resendOtpRef}>Don't get OTP? <LoadingLink text="Resend" handleClick={resendOtpClick} /></p>
                        <p ref={haveOtpRef} className="d-none"><LoadingLink text="I have OTP" handleClick={haveOtpClick} /></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyAccount;