import React, {useEffect} from 'react';
import {getUserData} from "../../Helper/auth";

const SessionClockUserDefined = ({endTime}) => {
    const [seconds, setSeconds] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [hours, setHours] = React.useState(0);
    const [days, setDays] = React.useState(0);
    const [diff, setDiff] = React.useState(0);
    let interval = setInterval(() => {
        const time = endTime;
        const now = Math.floor(Date.now() / 1000);
        const diff = time - now;
        const days = Math.floor(diff / 86400);
        const hours = Math.floor((diff - (days * 86400)) / 3600);
        const minutes = Math.floor((diff - (days * 86400) - (hours * 3600)) / 60);
        const seconds = diff - (days * 86400) - (hours * 3600) - (minutes * 60);
        setDiff(diff);
        setSeconds(seconds);
        setMinutes(minutes);
        setHours(hours);
        setDays(days);
    }, 1000);
    useEffect(() => {
        return () => {
            clearInterval(interval);
        }
    }, []);
    return (
        <div className="d-inline">
            {diff >= 0 ? `${days > 9 ? days : '0'+days}:${hours > 9 ? hours : '0'+hours}:${minutes > 9 ? minutes : '0'+minutes}:${seconds > 9 ? seconds : '0'+seconds}` : '00:00:00:00'}
        </div>
    );
};

export default SessionClockUserDefined;