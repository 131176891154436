import React from 'react';
import {Route, Routes} from "react-router-dom";
import Live from "./Live";
import LivePlayer from "./LivePlayer";

const Lives = () => {
    return (
        <div>
            <Routes>
                <Route path={'/'} element={<Live/>} />
                <Route path={':slug'} element={<LivePlayer/>} />
            </Routes>
        </div>
    );
};

export default Lives;