import React, {useContext, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileMedical, faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import axios from "axios";
import {UserContext} from "../../../App";
import LoadingButton from "../../../Components/Utilities/LoadingButton";

const AddExpenseModal = ({show, setShow, loadExpenses}) => {
    const [user] = useContext(UserContext);
    const initialData = {
        title: '',
        budget: 0,
        deposits: [{amount: '', description: '', date: ''}],
        comment: '',
    }
    const [data, setData] = useState(initialData);
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.id]: e.target.value
        });
    }
    const handleDepositsChange = (e, index) => {
        const deposits = [...data.deposits];
        deposits[index][e.target.id] = e.target.value;
        setData({
            ...data,
            budget: deposits.reduce((a, b) => a + (Number.parseInt(b['amount']) || 0), 0),
            deposits: deposits
        });
    }
    const handleAdd = (setLoading) => {
        if(data.title !== '' && data.budget > 0 && data.deposits.length > 0){
            let depositError = {error: false, message: ''}
            data.deposits.forEach((deposit, index) => {
                if(deposit.amount === '' || deposit.date === ''){
                    depositError.error = true;
                    depositError.message = 'Please fill all the fields';
                }
            });
            if(!depositError.error){
                    setLoading(true);
                    axios.post(process.env.REACT_APP_API_BASE_URL + '/prp/expense/add', data, {
                        headers: {
                            Authorization: 'Bearer ' + user.token
                        },
                    }).then(response => {
                        setLoading(false);
                        if (response.data.error) {
                            toast(response.data.error, {type: 'error'});
                        } else {
                            toast('Expense created successfully', {type: 'success'});
                            setShow(false);
                            setData(initialData);
                            loadExpenses();
                        }
                    }).catch(error => {
                        setLoading(false);
                        toast('Something went wrong', {type: 'error'});
                    });
            }else{
                toast.error(depositError.message);
            }
        }else{
            toast.error('Please fill all the fields');
        }
    }
    return (
        <Modal className="modal-lg" centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal ">
                <Modal.Header className="bg-a bg-gradient">
                    <Modal.Title className="text-warning"><FontAwesomeIcon icon={faFileMedical}/> New Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title</label>
                        <input type="text" className="form-control" id="title" onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="budget" className="form-label">Budget</label>
                        <input type="number" className="form-control" id="budget" onChange={handleChange} value={data.budget}/>
                    </div>
                    <div className="mb-3 mt-3">
                        <label htmlFor="deposits" className="form-label">Deposits</label>
                        {
                            data.deposits.map((deposit, index) => {
                                return (
                                    <div key={index} className="row mt-2">
                                        <div className="col-2">
                                            <input type="number" className="form-control" placeholder="Amount" id="amount" onChange={(e) => handleDepositsChange(e, index)}/>
                                        </div>
                                        <div className="col-6">
                                            <input type="text" className="form-control" placeholder="Description" id="description" onChange={(e) => handleDepositsChange(e, index)}/>
                                        </div>
                                        <div className="col-3">
                                            <input type="date" className="form-control" placeholder="date" id="date" onChange={(e) => handleDepositsChange(e, index)}/>
                                        </div>
                                        <div className="col-1">
                                            <label htmlFor="delete" className="form-label"></label>
                                            <button className={`btn btn-b ${index === 0 && 'disabled'}`} onClick={() => {
                                                const deposits = [...data.deposits];
                                                if(deposits.length > 1){
                                                    deposits.splice(index, 1);
                                                    setData({
                                                        ...data,
                                                        budget: deposits.reduce((a, b) => a + (Number.parseInt(b['amount']) || 0), 0),
                                                        deposits: deposits
                                                    });
                                                }
                                            }}>
                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className="col-12">
                            <button className="btn btn-b float-end" onClick={() => {
                                const deposits = [...data.deposits];
                                deposits.push({amount: '', description: '', date: ''});
                                setData({
                                    ...data,
                                    deposits: deposits
                                });
                            }}>
                                <FontAwesomeIcon icon={faPlusCircle}/>
                            </button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <textarea className="form-control" id="comment" rows="3" onChange={handleChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>Close</button>
                    <LoadingButton text="Create" onClick={handleAdd}/>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default AddExpenseModal;