import React from 'react';
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const DetailsModal = ({show, setShow, totalSpend, expense}) => {
    return (
        <Modal centered show={show} backdrop="static" onHide={()=>setShow(false)}>
            <div className="logout-modal">
                <Modal.Header className="bg-a bg-gradient d-flex flex-column align-items-center justify-content-center">
                    <h2 className="color-a">Expense Details</h2>
                    <p className="color-b">{expense.comment}</p>
                </Modal.Header>
                <Modal.Body className="bg-a">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                            <td className="color-a">Budget</td>
                            <td className="color-a">:</td>
                            <td className="color-a">{expense.budget}</td>
                        </tr>
                        <tr>
                            <td className="color-a">Total Spend</td>
                            <td className="color-a">:</td>
                            <td className="color-a">{totalSpend}</td>
                        </tr>
                        <tr>
                            <td className="color-a">Remaining</td>
                            <td className="color-a">:</td>
                            <td className="color-a">{expense.budget - totalSpend}</td>
                        </tr>
                        </tbody>
                    </table>

                    <h4 className="color-a text-center">Deposits</h4>
                    <table className="table table-bordered border-secondary table-sm">
                        <thead>
                        <tr className="text-center">
                            <th className="color-a">Date</th>
                            <th className="color-a">Amount</th>
                            <th className="color-a">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {show && JSON.parse(expense.deposits).map((deposit, index) => (
                            <tr className="text-center color-b" key={index}>
                                <td>{moment(deposit.date).format('DD MMM YYYY')}</td>
                                <td>{deposit.amount}</td>
                                <td>{deposit.description}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </Modal.Body>
                <Modal.Footer className="bg-a">
                    <button className="btn btn-b" onClick={()=>setShow(false)}>OK</button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default DetailsModal;